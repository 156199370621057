import { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react'

import { Pose } from '@tensorflow-models/pose-detection'

import { useAvatar } from '~/hooks/useAvatar'
import { TSendAvatarImage } from '~/hooks/useAvatar/types'
import { useCameraPermission } from '~/hooks/useCameraPermission'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'
import { useTryonContext } from '~/context/Tryon'

import { Camera } from '~/components/Camera'
import { ICameraHandler } from '~/components/Camera/types'
import { PoseValidation } from '~/components/Camera/utils/pose-validation'
import { CameraControls } from '~/components/CameraControls'
import { IAudioPlayer } from '~/components/CameraControls/types'
import { ImageSlotList } from '~/components/ImageSlotList'
import { InstructionalBalloons } from '~/components/InstructionalBalloons'
import { IInstructionalBalloonsProps } from '~/components/InstructionalBalloons/types'
import { PermissionsDeniedCamera } from '~/components/PermissionsDeniedCamera'
import { PermissionsRequestCamera } from '~/components/PermissionsRequestCamera'
import { PhotoFeedbackArea } from '~/components/PhotoFeedbackArea'
import { Positions } from '~/components/Positions'
import { TPosition, TPositionError } from '~/components/Positions/types'
import { RoutesHeader } from '~/components/RoutesHeader'
import { Timer } from '~/components/Timer'

import theme from '~/theme'

import { TAgeGroup, TGender } from '~/entities'
import { isCurrentProductsWearable } from '~/screens/Models/utils/shouldRemoveProductsFromTryOn'
import Tracking from '~/utils/tracking'

import * as Styled from './styles'
import { AudioConfirmPrompt } from './components/AudioConfirmPrompt'
import { screenCaptureValues, screenInitialValues, screenPositionsValues, screenSuccessValues } from './constants'
import { TReducerState, TStepPhotoWasSent, TStepsName } from './types'

const poseValidation = new PoseValidation()
const timer = 3
const animationTime = 300
const timerPreview = 1700
const timerFeedbackError = 5000
const TIMER_ERROR_CAPTURE_TIMEOUT = 5000
const TIMEOUT_FALLBACK_PICTURE = 30000

const reducerState: TReducerState = (state, nextState) => ({ ...state, ...nextState })

export const CameraScreen: FC = () => {
  const { navigate } = useNavigation()
  const { attempts, cameraPermission, requestCameraPermission, setCameraPermission, deviceCameras } =
    useCameraPermission()
  const { createAvatar, sendAvatarImage } = useAvatar()
  const { setAvatarState, setPositionsState, stateAvatar } = useAvatarContext()
  const { setTryonState, clearStates } = useTryonContext()
  const { startTryon, removeTryon } = useTryon()
  const { getPartner } = usePartner()

  const [audioPlayer, setAudioPlayer] = useState<IAudioPlayer>()

  const [screenStates, setScreenStates] = useReducer(reducerState, {
    showMessage: false,
    showPosition: true,
    showConfirmAudioPrompt: true,
    runDetector: false,
    slotList: [],
    cameraPositionSuccess: false,
    cameraMode: 'user',
    initDetector: false,
  })

  const cameraRef = useRef<ICameraHandler>(null)
  const timeoutFeedbackErrorKey = useRef<NodeJS.Timer>()
  const isFeedbackErrorAble = useRef(true)
  const errorLogTimeout = useRef<NodeJS.Timer>()
  const fallbackPictureTimeoutRef = useRef<NodeJS.Timer>()
  const shouldTakeFallbackPicture = useRef<boolean>(false)

  const shouldLogPoseError = useRef<boolean>(false)
  const isFeedbackSuccessAble = useRef(true)
  const stepPhotoWasSent = useRef<TStepPhotoWasSent>({
    firstStep: false,
    secondStep: false,
  })

  const handleClickGetPermission = async () => {
    await requestCameraPermission()

    Tracking.logEvent('CAMERA_ACCESS', { widget: true })
  }

  const handleStartCamera = async () => {
    audioPlayer?.stop()

    if (shouldLogPoseError.current) {
      clearErrorCaptureTimeout()
    }

    Tracking.logEvent('CAMERA_CAPTURE', {
      widget: true,
    })

    setScreenStates({
      currentStep: 'firstStep',
      showPosition: true,
      initDetector: true,
    })
  }

  const handleSwitchCamera = () => {
    const cameraMode = cameraRef.current?.switchCamera()

    setScreenStates({ cameraMode })
  }

  const handleTakePhoto = (step: TStepsName) => {
    setScreenStates({ runDetector: false, cameraPositionSuccess: false })

    const base64 = cameraRef.current?.takePhoto()

    // Quando retorna undefined, o handleCameraError é executado pelo componente Camera
    if (!base64) return

    const slotList = [...screenStates.slotList, base64]

    audioPlayer?.play(screenCaptureValues.audio)

    setScreenStates({ photoPreview: base64, slotList })

    Tracking.logEvent('AVATAR_DONE', {
      camera: screenStates.cameraMode === 'user' ? 'front' : 'back',
      photo: step === 'firstStep' ? 1 : 2,
      gender: stateAvatar?.data?.gender,
      height: stateAvatar?.data?.height,
      weight: stateAvatar?.data?.weight,
      age: stateAvatar?.data?.age,
      age_group: stateAvatar?.data?.age_group,
      widget: true,
    })

    if (step === 'firstStep') {
      createAvatar({ data: base64, setState: setAvatarState })
    }

    setTimeout(() => {
      if (step === 'secondStep') {
        navigate('ProcessingScreen', { base64SlotList: slotList })
        return
      }

      isFeedbackSuccessAble.current = true

      setScreenStates({
        showPosition: true,
        photoPreview: undefined,
        currentStep: 'secondStep',
      })
    }, animationTime + timerPreview)
  }

  const handleCameraError = (error: string) => {
    clearSecondPhotoFallbackTimer()

    // Error on take a photo
    if (error === 'Navegador e/ou dispositivo incompatível.') {
      navigate('ProcessingFailure')

      return
    }

    // No camera access
    setCameraPermission('BLOCKED')
  }

  const startTimeoutError = () => {
    isFeedbackErrorAble.current = false

    timeoutFeedbackErrorKey.current = setTimeout(() => {
      isFeedbackErrorAble.current = true
    }, timerFeedbackError)
  }

  const clearTimeoutError = () => {
    isFeedbackErrorAble.current = true

    clearTimeout(timeoutFeedbackErrorKey.current as NodeJS.Timeout)
  }

  const startSecondPhotoFallbackTimer = () => {
    fallbackPictureTimeoutRef.current = setTimeout(() => {
      shouldTakeFallbackPicture.current = true
    }, TIMEOUT_FALLBACK_PICTURE)
  }

  const clearSecondPhotoFallbackTimer = () => {
    shouldTakeFallbackPicture.current = false

    clearTimeout(fallbackPictureTimeoutRef.current as NodeJS.Timeout)
  }

  const startErrorCaptureTimeout = () => {
    errorLogTimeout.current = setTimeout(() => {
      shouldLogPoseError.current = true
    }, TIMER_ERROR_CAPTURE_TIMEOUT)
  }

  const clearErrorCaptureTimeout = () => {
    shouldLogPoseError.current = false

    clearTimeout(errorLogTimeout.current as NodeJS.Timeout)
  }

  const onTakePhoto = useCallback(() => {
    if (!stateAvatar?.data?.avatar_uuid || !screenStates.slotList.length) return

    screenStates.slotList.forEach((image, index) => {
      const currentPosition = index === 0 ? 'firstStep' : 'secondStep'

      if (stepPhotoWasSent.current[currentPosition]) return

      const data: TSendAvatarImage['data'] = {
        avatar_uuid: stateAvatar?.data?.avatar_uuid as string,
        type: screenPositionsValues[currentPosition].type,
        image,
      }

      stepPhotoWasSent.current[currentPosition] = true

      sendAvatarImage({ data, setState: setPositionsState })
    })
  }, [sendAvatarImage, setPositionsState, stateAvatar?.data?.avatar_uuid, screenStates.slotList])

  const onAvatarCreated = useCallback(() => {
    const currentTryon = TryonStorageInstance.get()
    const isAvatarNotReady =
      !currentTryon || !stateAvatar?.data?.avatar_uuid || stateAvatar?.errors?.create || stateAvatar?.isLoading

    if (isAvatarNotReady) return

    const shouldSkip = !isCurrentProductsWearable({
      avatarGender: stateAvatar.data?.gender as TGender,
      avatarAgeGroup: stateAvatar.data?.age_group as TAgeGroup,
      currentTryon: currentTryon,
    })
    if (shouldSkip) {
      removeTryon({ setState: clearStates })
      return
    }

    const start = async () => {
      const { data: partner } = await getPartner()

      startTryon({
        data: {
          idModel: stateAvatar?.data?.avatar_uuid as string,
          products: currentTryon,
          from: 'avatar',
          upscale: partner?.upscale,
        },
        setState: setTryonState,
      })
    }

    start()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTryonState, startTryon, stateAvatar, getPartner])

  const onScreenRender = useCallback(() => {
    setScreenStates({ showMessage: true, showConfirmAudioPrompt: false })

    audioPlayer?.play(screenInitialValues.audio).then(() => {
      setScreenStates({ showMessage: false })

      setTimeout(() => {
        handleStartCamera()
      }, 1000)
    })

    Tracking.logEvent('AVATAR_AUDIO', { widget: true })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioPlayer])

  const onScreenClose = () => {
    audioPlayer?.stop()

    clearTimeout(timeoutFeedbackErrorKey.current as NodeJS.Timeout)
  }

  const onStepChange = () => {
    if (!screenStates.currentStep) return

    if (shouldLogPoseError.current) {
      clearErrorCaptureTimeout()
    }

    if (screenStates.currentStep === 'secondStep') {
      startSecondPhotoFallbackTimer()
    }

    setScreenStates({ runDetector: false, showMessage: true })

    poseValidation.poseType = screenPositionsValues[screenStates.currentStep].type

    audioPlayer?.play(screenPositionsValues[screenStates.currentStep].audio).then(() => {
      startErrorCaptureTimeout()
      setScreenStates({ runDetector: true, showMessage: false })
    })
  }

  const onPoseSuccess = () => {
    if (!isFeedbackSuccessAble.current) return

    isFeedbackSuccessAble.current = false

    clearTimeoutError()

    setScreenStates({
      cameraPositionSuccess: true,
      cameraPositionError: undefined,
      showMessage: true,
      showPosition: false,
    })

    audioPlayer?.play(screenSuccessValues.audio).then(() => setScreenStates({ showMessage: false }))
  }

  const onPoseError = (step: TStepsName, error: TPositionError) => {
    setScreenStates({
      cameraPositionSuccess: false,
      cameraPositionError: error,
      showPosition: true,
    })

    isFeedbackSuccessAble.current = true

    if (!isFeedbackErrorAble.current) return

    setScreenStates({ showMessage: true, runDetector: false })

    audioPlayer?.play(screenPositionsValues[step].error.audio).then(() => {
      setScreenStates({ showMessage: false, runDetector: true })
      startTimeoutError()
    })
  }

  const onPoseChange = (poses: Pose[], height: number, width: number) => {
    if (screenStates.currentStep === 'secondStep' && shouldTakeFallbackPicture.current) {
      onPoseSuccess()
      return
    }

    if (!poses.length) return onPoseError(screenStates.currentStep as TStepsName, 'body' as TPositionError)

    const response = poseValidation.validatePose(poses[0], height, width)

    response.status
      ? onPoseSuccess()
      : onPoseError(screenStates.currentStep as TStepsName, response.error as TPositionError)
  }

  const getFeedbackMessage = (step?: TStepsName): string => {
    if (step && screenStates.cameraPositionError)
      return screenPositionsValues[step].error.messages[screenStates.cameraPositionError]

    if (screenStates.cameraPositionSuccess) return screenSuccessValues.message

    return step ? screenPositionsValues[step].message : screenInitialValues.message
  }

  const getFeedbackMessageStatus = (): IInstructionalBalloonsProps['status'] => {
    if (screenStates.cameraPositionError) return 'danger'

    if (screenStates.cameraPositionSuccess) return 'success'

    return 'default'
  }

  const getFeedbackPosition = (step?: TStepsName): TPosition =>
    step ? screenPositionsValues[step].position : screenInitialValues.position

  const getFeedbackPositionError = (): TPositionError | undefined => screenStates?.cameraPositionError

  useEffect(() => {
    return () => onScreenClose()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onStepChange, [screenStates.currentStep])

  useEffect(onTakePhoto, [onTakePhoto])

  useEffect(onAvatarCreated, [onAvatarCreated])

  return (
    <Styled.Container data-testid="camera-screen">
      <Styled.ContentHeader>
        <RoutesHeader contrast={cameraPermission !== 'BLOCKED'} closeButtonUrl="Models" />
      </Styled.ContentHeader>

      {cameraPermission === 'GRANTED' && (
        <>
          <AudioConfirmPrompt visible={screenStates.showConfirmAudioPrompt} handleConfirm={onScreenRender} />

          <Styled.Content data-testid="camera-screen-content">
            <Camera
              facingMode={screenStates.cameraMode}
              ref={cameraRef}
              runDetector={screenStates.runDetector}
              onPoseChange={onPoseChange}
              onError={handleCameraError}
              deviceCameras={deviceCameras}
              initDetector={screenStates.initDetector}
            />

            <PhotoFeedbackArea
              hideArea={!screenStates.showPosition}
              hasOverlay={screenStates.showPosition}
              areaColor={screenStates.cameraPositionError && theme.colors.coralRed}
            >
              {screenStates.cameraPositionSuccess && (
                <Timer
                  time={timer}
                  callback={() => handleTakePhoto(screenStates.currentStep as TStepsName)}
                  color={theme.colors.white}
                />
              )}
              {screenStates.showPosition && (
                <Positions
                  position={getFeedbackPosition(screenStates.currentStep)}
                  error={getFeedbackPositionError()}
                />
              )}
            </PhotoFeedbackArea>

            {screenStates.photoPreview && (
              <Styled.ImagePreview data-testid="camera-image-preview" src={screenStates.photoPreview} />
            )}

            <Styled.CaptureFeedback
              data-testid="camera-feedback-capture"
              animationStart={!!screenStates.photoPreview}
              animationTime={animationTime}
            />

            <Styled.SlotListWrapper>
              <ImageSlotList slotSize={2} slotList={screenStates.slotList} />
            </Styled.SlotListWrapper>

            {screenStates.showMessage && (
              <Styled.FeedbackMessageWrapper>
                <Styled.FeedbackMessageBox>
                  <InstructionalBalloons
                    width="100%"
                    description={getFeedbackMessage(screenStates.currentStep)}
                    status={getFeedbackMessageStatus()}
                  />
                </Styled.FeedbackMessageBox>
              </Styled.FeedbackMessageWrapper>
            )}
          </Styled.Content>
        </>
      )}

      <Styled.ControlsWrapper>
        <CameraControls getAudioPlayer={setAudioPlayer} onSwitchButtonClick={handleSwitchCamera} />
      </Styled.ControlsWrapper>

      {cameraPermission !== 'GRANTED' && !!attempts && (
        <Styled.PermissionWrapper>
          <PermissionsRequestCamera onClick={handleClickGetPermission} />
        </Styled.PermissionWrapper>
      )}
      {cameraPermission === 'BLOCKED' && !attempts && <PermissionsDeniedCamera />}
    </Styled.Container>
  )
}

import { FC, useEffect, useMemo } from 'react'

import { useNavigation } from '~/context/Navigation'

import { MobileUtils } from '~/utils/mobileUtils'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { TextButton } from '../TextButton'

export const PermissionsDeniedCamera: FC = () => {
  const mobileOS = useMemo(() => MobileUtils.getOS(), [])
  const { navigate } = useNavigation()

  const handleClickUseModel = () => {
    Tracking.logEvent('AVATAR_GALLERY', { widget: true })

    navigate('Models', { modelsTab: 'PRE' })
  }

  const handleRedirectButton = () => {
    if (mobileOS === 'Android') {
      Tracking.logEvent('CAMERA_ACTION', { option: 'link', widget: true })
      // TODO: Confirm Android URL
      window.open('https://support.google.com/chrome/answer/114662?hl=pt-BR&co=GENIE.Platform%3DAndroid')
      return
    }

    Tracking.logEvent('CAMERA_ACTION', { option: 'reload', widget: true })
    document.location.reload()
  }

  useEffect(() => {
    Tracking.logEvent('CAMERA_DENIED', { widget: true })
  }, [])

  return (
    <Styled.Container data-testid="permission-denied-container">
      <Styled.WrapperContent>
        <Styled.Emoji>😰</Styled.Emoji>

        <Styled.Title align="center">{translate('PERMISSION_DENIED_SCREEN_TITLE')}</Styled.Title>

        <Styled.Text align="center" data-testid="permission-denied-description">
          {mobileOS === 'Android'
            ? translate('PERMISSION_DENIED_SCREEN_ANDROID_DESCRIPTION')
            : translate('PERMISSION_DENIED_SCREEN_IOS_DESCRIPTION')}
        </Styled.Text>
      </Styled.WrapperContent>

      <Styled.WrapperFooter>
        <TextButton variant="outlined" testID="use-model-button" onClick={handleClickUseModel}>
          {translate('PERMISSION_DENIED_SCREEN_MODEL_BUTTON')}
        </TextButton>

        <TextButton testID="redirect-button" onClick={handleRedirectButton}>
          {mobileOS === 'Android' ? translate('GO_TO_LINK_NOTE') : translate('RELOAD')}
        </TextButton>
      </Styled.WrapperFooter>
    </Styled.Container>
  )
}

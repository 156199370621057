import { FC, useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'

import { TCouponBait } from '~/entities/Coupon'
import { PostHogUtil } from '~/utils/postHog'

import { useBackdropsContext } from '../Backdrops'
import { useNavigation } from '../Navigation'
import { CouponFlowsContext } from './context'

const DELAY_FOR_SHOWING_BAIT_OR_REWARD = 3000

export const CouponFlowsProvider: FC = ({ children }) => {
  const IS_PRIVACY_DISCLAIMER_FLAG_ENABLED = PostHogUtil.isFlagEnabled('PRIVACY_DISCLAIMER')

  const { isPrivacyDisclaimerVisible } = useBackdropsContext()

  const [isBaitVisible, setIsBaitVisible] = useState<Record<TCouponBait['type'], boolean>>({
    selfAvatar: false,
  })
  const [rewardToClaim, setRewardToClaim] = useState<TCouponBait['type'] | undefined>()

  const { route } = useNavigation()
  const { getAvatars } = useAvatar()

  useEffect(() => {
    if (route !== 'Home') return

    let timeoutId: NodeJS.Timeout
    if (getAvatars().length === 0) {
      if (!isPrivacyDisclaimerVisible || !IS_PRIVACY_DISCLAIMER_FLAG_ENABLED) {
        timeoutId = setTimeout(() => {
          setIsBaitVisible(prev => ({ ...prev, selfAvatar: true }))
        }, DELAY_FOR_SHOWING_BAIT_OR_REWARD)
      }
    } else {
      timeoutId = setTimeout(() => {
        setRewardToClaim('selfAvatar')
      }, DELAY_FOR_SHOWING_BAIT_OR_REWARD)
    }

    return () => clearTimeout(timeoutId)
  }, [IS_PRIVACY_DISCLAIMER_FLAG_ENABLED, getAvatars, isPrivacyDisclaimerVisible, route])

  const value = { isBaitVisible, setIsBaitVisible, rewardToClaim, setRewardToClaim }

  return <CouponFlowsContext.Provider value={value}>{children}</CouponFlowsContext.Provider>
}

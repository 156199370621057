import { TProduct } from '~/hooks/usePartner/types'

import { IPartner } from '~/entities'
import { IDorisConfig } from '~/entities/DorisConfig'

export type TMessageType = 'whatsapp' | 'email'

const messages = {
  single: encodeURI('Olá!\n\n Como faço para ter mais informações do produto?\n\n'),
  multiple: encodeURI('Olá!\n\n Como faço para ter mais informações dos produtos?\n\n'),
}

export interface IGetTemplateProps {
  partner: IPartner
  products: TProduct[]
  type: TMessageType
}

const getHeader = ({ email, whatsapp }: IPartner) => {
  return whatsapp ? `https://wa.me/+${whatsapp}?text=` : `mailto:${email}?subject=Contato&body=`
}

export const getType = ({ whatsapp, email }: IPartner): TMessageType | null => {
  if (whatsapp) return 'whatsapp'
  if (email) return 'email'
  return null
}

export const getTemplate = ({ partner, products, type }: IGetTemplateProps) => {
  if (!products.length) return

  const header = type === 'whatsapp' ? getHeader({ whatsapp: partner.whatsapp }) : getHeader({ email: partner.email })

  if (products.length === 1)
    return `${header}${messages['single']}${products[0].identifier}-${products[0].name}${
      products[0].selectedSize ? encodeURI(` no tamanho ${products[0].selectedSize}`) : ''
    }`

  let multipleTemplate = `${header}${messages['multiple']}`

  products.forEach(
    product =>
      (multipleTemplate += `${product.identifier}-${product.name}${encodeURI(
        product.selectedSize ? ` no tamanho ${product.selectedSize}\n` : `\n`,
      )}`),
  )

  return `${multipleTemplate}`
}

export const configHasDeletionFlag = (config: IDorisConfig[]) => {
  return config.some(entity => entity.path === 'delete_avatar' || entity.path === 'avatar_delete')
}

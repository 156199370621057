import { useCallback, useState } from 'react'

import { AxiosClient } from '~/clients/axios'
import { Sentry } from '~/clients/sentry'
import { IProduct } from '~/entities'

import { IFetchParams } from './types'

export const useGetOutfit = () => {
  const [loading, setLoading] = useState(false)
  const [isLastPage, setIsLastPage] = useState(false)

  const fetch = useCallback(
    async ({ limit = 20, offset = 0, ...payload }: IFetchParams): Promise<Array<IProduct> | undefined> => {
      const params = { limit: limit.toString(), offset: offset?.toString(), ...payload }

      setLoading(true)

      try {
        const {
          data: { data, remaining },
        } = await AxiosClient().get<{ data: Array<IProduct>; remaining: number }>('/outfit', {
          params,
          headers: {
            'Content-Type': 'application/json',
          },
        })

        setIsLastPage(!remaining)
        return data
      } catch (error) {
        Sentry.captureException({
          errorName: error.name,
          errorMessage: 'ERROR_WHILE_FETCHING_SIZE_TABLE_DATA',
          filePath: 'src/hooks-queries/products/hook.ts',
          functionName: 'fetch',
          payload: params,
        })
      } finally {
        setLoading(false)
      }
    },
    [],
  )

  return {
    fetch,
    loading,
    isLastPage,
  }
}

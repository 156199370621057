import { useCallback, useState } from 'react'

import { TCouponBait, TCouponReward } from '~/entities/Coupon'

import { IUseGetCouponBaits, IUseGetCouponReward } from './types'

const COUPON_BAITS: TCouponBait[] = [
  {
    id: '1',
    type: 'selfAvatar',
    copy: {
      title: 'Prove em você e ganhe R$15 OFF',
      text: 'Envie sua foto, veja como a roupa e cada tamanho fica em você e aproveite o desconto!',
    },
  },
]

export const useGetCouponBaits = (): IUseGetCouponBaits => {
  const [baits, setBaits] = useState<TCouponBait[]>()
  const [loadingBaits, setLoadingBaits] = useState(false)
  const [errorBaits, setErrorBaits] = useState(false)

  const fetchBaits = useCallback(async (): Promise<void> => {
    setLoadingBaits(true)

    try {
      const response = COUPON_BAITS

      /* ~ This logic should live in the backend, once it's developed ~ */
      // Make sure that there is one bait of each type at most
      const uniqueTypes = new Set<TCouponBait['type']>()
      const baits: TCouponBait[] = []
      for (const bait of response) {
        if (!uniqueTypes.has(bait.type)) {
          uniqueTypes.add(bait.type)
          baits.push(bait)
        }
      }
      /* ~ O ~ */

      setBaits(baits)
    } catch {
      setErrorBaits(true)
    } finally {
      setLoadingBaits(false)
    }
  }, [])

  return {
    fetchBaits,
    baits,
    loadingBaits: loadingBaits,
    errorBaits: errorBaits,
  }
}

const COUPON_REWARD: TCouponReward = {
  id: '1',
  type: 'selfAvatar',
  code: 'DORIS15',
  copy: {
    title: 'Use o cupom {{code}} e ganhe R$15 OFF',
    text: 'É só inserir o cupom na hora de fechar sua compra para ganhar o desconto!',
  },
}

export const useGetCouponReward = (): IUseGetCouponReward => {
  const [reward, setReward] = useState<TCouponReward>()
  const [loadingReward, setLoadingReward] = useState(false)
  const [errorReward, setErrorReward] = useState(false)

  const fetchReward = useCallback(async (): Promise<void> => {
    setLoadingReward(true)

    try {
      const response = COUPON_REWARD

      setReward(response)
    } catch {
      setErrorReward(true)
    } finally {
      setLoadingReward(false)
    }
  }, [])

  return {
    fetchReward,
    reward,
    loadingReward,
    errorReward,
  }
}

import theme from '~/theme'

import { TSizingTagMapItem, TSizingTagsMap } from './types'

export const getSizingTagMessagesMap = (): TSizingTagMapItem => ({
  TOO_SMALL: 'SIZING_TAG_TOO_SMALL',
  TIGHT: 'SIZING_TAG_TIGHT',
  SOME_TIGHT: 'SIZING_TAG_SOME_TIGHT_DESCRIPTION',
  FIT: 'SIZING_TAG_FIT_PERFECT',
  SOME_LOOSE: 'SIZING_TAG_SOME_LOOSE_DESCRIPTION',
  LOOSE: 'SIZING_TAG_LOOSE',
  TOO_BIG: 'SIZING_TAG_TOO_BIG',
})

export const getSizingBodyPartMap = {
  chest: {
    MALE: 'CHEST_MALE',
    FEMALE: 'CHEST_FEMALE',
  },
  waist: {
    MALE: 'WAIST',
    FEMALE: 'WAIST',
  },
  hip: {
    MALE: 'HIP',
    FEMALE: 'HIP',
  },
}

export const sizingTagMessagesColorsMap: TSizingTagMapItem = {
  TOO_SMALL: theme.colors.status.error,
  TIGHT: theme.colors.status.error,
  SOME_TIGHT: theme.colors.status.warning,
  FIT: theme.colors.status.success,
  SOME_LOOSE: theme.colors.status.warning,
  LOOSE: theme.colors.status.error,
  TOO_BIG: theme.colors.status.error,
}

export const getTagsMap = (): TSizingTagsMap => ({
  labels: getSizingBodyPartMap,
  messages: getSizingTagMessagesMap(),
  colors: sizingTagMessagesColorsMap,
})

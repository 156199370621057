import { FC, useEffect } from 'react'

import { useAppScaling } from '~/hooks/useAppScaling'
import useStartEvent from '~/hooks/useStartEvent'
import { useSyncSession } from '~/hooks/useSyncSession'

import { TCallbackRef, useHandleBuyButton } from '~/context/HandleBuyButton'
import { useNavigation } from '~/context/Navigation'
import { useWidgetState } from '~/context/WidgetState'

import { withAvatarProcessingScreen } from '~/hocs/withAvatarProcessingScreen'
import { withAvatarTimeoutProcessingScreen } from '~/hocs/withAvatarTimeoutProcessingScreen'

import Route from '~/components/Route'

import { CameraScreen } from '~/screens/CameraScreen'
import { Home } from '~/screens/Home'
import { HowToSendMyPhotoScreen } from '~/screens/HowToSendMyPhoto'
import { InformData } from '~/screens/InformData'
import { Models } from '~/screens/Models'
import { ProcessingFailure } from '~/screens/ProcessingFailure'
import { ProcessingScreen } from '~/screens/ProcessingScreen'
import { SizingScreen } from '~/screens/SizingScreen'
import { SizingSuggestionScreen } from '~/screens/SizingSuggestionScreen'
import { SplashScreen } from '~/screens/SplashScreen'
import { TermsScreen } from '~/screens/TermsScreen'
import { Timeout } from '~/screens/Timeout'
import { Tips } from '~/screens/Tips'
import { PostHogUtil } from '~/utils/postHog'

import { Container, Content } from './styles'

const AvatarProcessingScreen = withAvatarProcessingScreen(ProcessingScreen)
const AvatarTimeoutProcessingScreen = withAvatarTimeoutProcessingScreen(ProcessingScreen)

export interface IAppConfigProps {
  handleBuyButton?: TCallbackRef
}

const AppContainer: FC<IAppConfigProps> = ({ handleBuyButton }) => {
  const { state } = useWidgetState()
  const { scale } = useAppScaling()
  const { route } = useNavigation()
  const { shouldStartSubscription, startSubscription } = useSyncSession()

  useStartEvent()

  const { handleBuyButtonRef } = useHandleBuyButton()

  useEffect(() => {
    if (!handleBuyButtonRef) return

    handleBuyButtonRef.current = handleBuyButton
  }, [handleBuyButton, handleBuyButtonRef])

  useEffect(() => {
    if (!shouldStartSubscription()) return

    startSubscription()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route])

  useEffect(() => {
    if (state === 'open') {
      PostHogUtil.startSessionRecording()

      return
    }
  }, [state])

  return (
    <Container data-testid="app-container" open={state === 'open'} scale={scale}>
      <Content data-testid="content">
        <Route path="SplashScreen" component={SplashScreen} />
        <Route path="Home" component={Home} />
        <Route path="Camera" component={CameraScreen} />
        <Route path="Models" component={Models} />
        <Route path="ProcessingFailure" component={ProcessingFailure} />
        <Route path="ProcessingScreen" component={AvatarProcessingScreen} />
        <Route path="TimeoutProcessingScreen" component={AvatarTimeoutProcessingScreen} />
        <Route path="Timeout" component={Timeout} />
        <Route path="InformData" component={InformData} />
        <Route path="SizingScreen" component={SizingScreen} />
        <Route path="Tips" component={Tips} />
        <Route path="TermsScreen" component={TermsScreen} />
        <Route path="HowToSendMyPhotoScreen" component={HowToSendMyPhotoScreen} />
        <Route path="SizingSuggestionScreen" component={SizingSuggestionScreen} />
      </Content>
    </Container>
  )
}

export default AppContainer

import { FC, useEffect, useState } from 'react'

import { RoutesHeader } from '~/components/RoutesHeader'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { scanGif } from './constants'
import { IProcessingScreenProps } from './types'

export const ProcessingScreen: FC<IProcessingScreenProps> = ({
  title = translate('PROCESSING_SCREEN_TITLE'),
  sentences,
  closeUrl,
}) => {
  const [index, setIndex] = useState(0)

  useEffect(() => {
    const intervalKey = setInterval(() => {
      setIndex(prev => {
        const next = prev + 1
        if (next > sentences.length - 1) {
          return 0
        }
        return next
      })
    }, 4000)

    return () => {
      clearInterval(intervalKey)
    }
  }, [sentences])

  return (
    <Styled.Container data-testid="processing-screen">
      <RoutesHeader title={title} closeButtonUrl={closeUrl} />

      <Styled.ContentWrapper>
        <img src={scanGif} alt="Scanning" height={314} />
      </Styled.ContentWrapper>

      <Styled.FooterText align="center" data-testid="footer-text">
        {sentences[index]}
      </Styled.FooterText>
    </Styled.Container>
  )
}

import { gql } from '@apollo/client'

export const GET_DORIS_CONFIG = gql`
  query doris_configs($group_id: Int!) {
    doris_config(where: { group_id: { _eq: $group_id } }) {
      id
      group_id
      created_at
      path
      updated_at
      value
    }
  }
`

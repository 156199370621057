import { useCallback } from 'react'

import { useQueryAutomix } from '~/hooks-queries/automix'
import { ICurrentModel } from '~/hooks/useCurrentModel/types'

import { IAutomix, TAgeGroup, TGender } from '~/entities'

import { IStorage, IUseAutomix, TModelStorage } from './types'

const storageKey = '@doris:automix'

export const useAutomix = (): IUseAutomix => {
  const { fetchCombination } = useQueryAutomix()

  const getStorage = useCallback((): IStorage | null => {
    const storage = localStorage.getItem(storageKey)
    return storage ? JSON.parse(storage) : null
  }, [])

  const getModelStorage = useCallback(
    ({ model }: { model: ICurrentModel }): TModelStorage => {
      const storage = getStorage()

      if (!storage) return []

      return (storage[model.id] as Array<number>) || []
    },
    [getStorage],
  )

  const deleteModelStorage = useCallback(
    ({ model }: { model: ICurrentModel }) => {
      const storage = getStorage()

      /* istanbul ignore next */
      if (!storage) return

      delete storage[model.id]

      localStorage.setItem(storageKey, JSON.stringify(storage))
    },
    [getStorage],
  )

  const getCombination = useCallback(
    ({ model }: { model: ICurrentModel }): Promise<IAutomix> => {
      return new Promise(async (resolve, reject) => {
        const modelStorage = getModelStorage({ model })

        let automixCombination: IAutomix | null = null
        let automixError

        try {
          automixCombination = await fetchCombination({
            gender: model.gender as TGender,
            age_group: model.age_group as TAgeGroup,
            filteredCombinations: modelStorage,
          })
        } catch (error) {
          automixError = error
        }

        if (automixError) {
          reject(new Error(automixError.message))
          return
        }

        if (!automixCombination) {
          deleteModelStorage({ model })

          automixCombination = await fetchCombination({
            gender: model.gender as TGender,
            age_group: model.age_group as TAgeGroup,
            filteredCombinations: [],
          })

          if (!automixCombination) {
            reject(new Error('Não há combinações disponíveis'))
            return
          }
        }

        resolve(automixCombination)
      })
    },
    [getModelStorage, fetchCombination, deleteModelStorage],
  )

  const setCombination = useCallback(
    ({ model, combination }: { model: ICurrentModel; combination: IAutomix }) => {
      const modelStorage = getModelStorage({ model })
      const storage = getStorage() || {}

      modelStorage.push(combination.id)

      localStorage.setItem(
        storageKey,
        JSON.stringify({
          ...storage,
          [model.id]: modelStorage,
        }),
      )
    },
    [getModelStorage, getStorage],
  )

  return {
    getCombination,
    setCombination,
  }
}

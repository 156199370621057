import { useCallback, useEffect } from 'react'

import { useGetCouponBaits, useGetCouponReward } from '~/hooks-queries/coupon'

import { useCouponFlowsContext } from '~/context/CouponFlows'

import { TCouponBait } from '~/entities/Coupon'

import BaitBackdrop from './components/BaitBackdrop'
import RewardBackdrop from './components/RewardBackdrop'
import { getCouponFlowLocalStorage, markRewardAsClaimed, showCouponFlow } from './utils'

const CouponFlowsBackdrop = () => {
  const { isBaitVisible, setIsBaitVisible, rewardToClaim, setRewardToClaim } = useCouponFlowsContext()

  const { fetchBaits, baits, loadingBaits, errorBaits } = useGetCouponBaits()
  const { fetchReward, reward, loadingReward, errorReward } = useGetCouponReward()

  const getCouponFlows = useCallback(async () => {
    await fetchBaits()
  }, [fetchBaits])

  const handleCloseBait = useCallback(
    (type: TCouponBait['type']) => {
      setIsBaitVisible(prev => ({ ...prev, [type]: false }))
    },
    [setIsBaitVisible],
  )

  const getCouponReward = useCallback(async () => {
    await fetchReward()
  }, [fetchReward])

  const handleCloseReward = (type: TCouponBait['type']) => {
    setRewardToClaim(undefined)
    markRewardAsClaimed(type)
  }

  useEffect(() => {
    const couponFlowLocalStorage = getCouponFlowLocalStorage()

    // This condition avoids calling the API if it already has been claimed.
    // If more coupon flows are added, this condition should be removed (or check before that it has the same keys as isBaitVisible)
    if (
      couponFlowLocalStorage?.hasRewardBeenClaimed &&
      Object.values(couponFlowLocalStorage.hasRewardBeenClaimed).every(Boolean)
    ) {
      return
    }

    if (!baits) {
      getCouponFlows()
      return
    }
  }, [baits, getCouponFlows])

  useEffect(() => {
    if (rewardToClaim) {
      getCouponReward()
      return
    }
  }, [getCouponReward, rewardToClaim])

  return (
    <>
      {!(loadingBaits || errorBaits) &&
        baits?.map(({ id, type, copy }) => (
          <BaitBackdrop
            key={id}
            visible={isBaitVisible[type] && showCouponFlow(type)}
            title={copy.title}
            text={copy.text}
            close={() => handleCloseBait(type)}
          />
        ))}

      {!(loadingReward || errorReward) && (
        <RewardBackdrop
          visible={Boolean(rewardToClaim) && (reward ? showCouponFlow(reward.type) : false)}
          close={() => (reward ? handleCloseReward(reward.type) : null)}
          title={reward?.copy.title || ''}
          text={reward?.copy.text || ''}
          code={reward?.code || ''}
        />
      )}
    </>
  )
}

export default CouponFlowsBackdrop

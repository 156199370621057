import styled from 'styled-components'

import { Prefix, Title } from '~/components/Typography/styles'

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ContainerIcon = styled.div`
  margin-top: 20px;
  margin-bottom: 16px;
  padding: 10px;
`

export const TitleSizing = styled(Title)`
  font-weight: 700;
  margin-bottom: 16px;
`

export const CodeHighlight = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`

export const Text = styled(Prefix)`
  font-weight: 400;
`

export const ContainerButton = styled.div`
  display: flex;
  gap: 10px;
  margin-top: auto;
  padding: 20px 0 0;
  width: 100%;
`

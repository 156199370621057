import { gql } from '@apollo/client'

export const GET_AUTOMIX = gql`
  query automix($where: automix_bool_exp, $limit: Int = 50) {
    automix(where: $where, limit: $limit) {
      id
      top: productByTopProductId {
        id
        identifier
        parent_identifier
        thumbnail
        name
        url
        selling_price
        list_price
        outfit_uuid
        gender
        age_group
        category {
          id
          name
          type
        }
        product_options(order_by: { partner_size: { order: asc, id: asc } }) {
          has_stock
          unique_code
          partner_size {
            id
            name
            order
          }
        }
      }
      bottom: productByBottomProductId {
        id
        identifier
        parent_identifier
        thumbnail
        name
        url
        selling_price
        list_price
        outfit_uuid
        gender
        age_group
        category {
          id
          name
          type
        }
        product_options(order_by: { partner_size: { order: asc, id: asc } }) {
          has_stock
          unique_code
          partner_size {
            id
            name
            order
          }
        }
      }
    }
  }
`

import { FC, useEffect, useRef, useState } from 'react'

import { useAutomix } from '~/hooks/useAutomix'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { ICurrentModel } from '~/hooks/useCurrentModel/types'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'

import { useTermsContext } from '~/context/Terms'
import { useTryonContext } from '~/context/Tryon'

import { AnimationZoomOut } from '~/components/Animations'
import AutoMixButton from '~/components/AutoMixButton'
import { TextButton } from '~/components/TextButton'
import { Tooltip } from '~/components/Tooltip'
import { Walkthrough } from '~/components/Walkthrough'

import { IAutomix } from '~/entities'
import { PostHogUtil } from '~/utils/postHog'
import { translate } from '~/utils/translate'

import { ButtonContainer, ButtonsWrapper, Container, TooltipContentWrapper } from './styles'
import { IBottomActionsContainerProps } from './types'
import { getTooltipContent } from './utils/getTooltipContent'

export const BLACK_FRIDAY_TOOLTIP_POSTHOG_KEY = 'BLACK_FRIDAY_TOOLTIP'

const BottomActionsContainer: FC<IBottomActionsContainerProps> = ({
  onCombineClick,
  onChangeModelClick,
  walkthrough,
  visibleButtons,
  setScreenLoading,
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const combination = useRef<IAutomix>()
  const { getCombination, setCombination } = useAutomix()
  const { getPartner } = usePartner()
  const { getCurrentModel } = useCurrentModel()
  const { step, isDone, handleNavigate, handleSkip } = walkthrough
  const { terms } = useTermsContext()
  const { setTryonState, stateTryon } = useTryonContext()
  const { startTryon } = useTryon()

  const IS_FLAG_ENABLED = !!PostHogUtil.isFlagEnabled(BLACK_FRIDAY_TOOLTIP_POSTHOG_KEY)
  const IS_TOOLTIP_VIEWED = !!localStorage.getItem('@doris:viewed_black_friday_tooltip')
  const SHOULD_SHOW_TOOLTIP = !!(!IS_TOOLTIP_VIEWED && IS_FLAG_ENABLED)
  const TOOLTIP_CONTENT = getTooltipContent()

  const walkthroughActive = step <= 2 && !isDone && terms

  const handleSuggestion = async () => {
    try {
      setScreenLoading(true)

      combination.current = await getCombination({ model: getCurrentModel() as ICurrentModel })

      const { data: partner } = await getPartner()

      startTryon({
        data: {
          idModel: getCurrentModel().id,
          from: 'automix',
          isAutomix: true,
          products: combination.current.products,
          upscale: partner?.upscale,
        },
        setState: setTryonState,
      })
    } catch (e) {
      setScreenLoading(false)

      // eslint-disable-next-line no-console
      console.error(e)
    }
  }

  useEffect(() => {
    if (!stateTryon?.called || stateTryon?.isLoading || stateTryon?.error || stateTryon?.data?.from !== 'automix')
      return

    setCombination({ model: getCurrentModel() as ICurrentModel, combination: combination.current as IAutomix })
  }, [getCurrentModel, setCombination, stateTryon])

  const blackFridayTooltip: JSX.Element = (
    <TooltipContentWrapper>
      <h1 data-testid="promotional-tooltip-title">{TOOLTIP_CONTENT.title}</h1>
      <span data-testid="promotional-tooltip-description">{TOOLTIP_CONTENT.description}</span>
    </TooltipContentWrapper>
  )

  return (
    <Container active={walkthroughActive || isTooltipVisible} data-testid="bottom-actions-container">
      <ButtonsWrapper>
        <ButtonContainer data-testid="button-container" active={step === 0}>
          <AnimationZoomOut visible={visibleButtons}>
            <TextButton testID="combine-button" textTransform="capitalize" onClick={onCombineClick} disabled={!isDone}>
              {translate('BOTTOM_ACTIONS_COMBINE')}
            </TextButton>
          </AnimationZoomOut>
        </ButtonContainer>

        <ButtonContainer data-testid="button-container" active={step === 1}>
          <AnimationZoomOut visible={visibleButtons}>
            <Tooltip
              content={SHOULD_SHOW_TOOLTIP ? blackFridayTooltip : undefined}
              active={SHOULD_SHOW_TOOLTIP}
              width={325}
              padding="20px 20px"
              id="auto-mix"
              place="top"
              arrowWidth={10}
              borderRadius="16px"
              forceWidth
              onHidden={() => {
                localStorage.setItem('@doris:viewed_black_friday_tooltip', '1')
                setIsTooltipVisible(false)
              }}
              onVisible={() => setIsTooltipVisible(true)}
              timer={5000}
            >
              <AutoMixButton
                onClick={handleSuggestion}
                disabled={stateTryon?.isLoading || !isDone}
                usePromoIcon={IS_FLAG_ENABLED}
              />
            </Tooltip>
          </AnimationZoomOut>
        </ButtonContainer>

        <ButtonContainer data-testid="button-container" active={step === 2}>
          <AnimationZoomOut visible={visibleButtons}>
            <TextButton
              testID="change-model-button"
              textTransform="initial"
              onClick={onChangeModelClick}
              disabled={!isDone}
            >
              {translate('BOTTOM_ACTIONS_CHANGE_MODEL')}
            </TextButton>
          </AnimationZoomOut>
        </ButtonContainer>
      </ButtonsWrapper>

      {walkthroughActive && <Walkthrough step={step} onNavigate={handleNavigate} onSkip={handleSkip} />}
    </Container>
  )
}

export default BottomActionsContainer

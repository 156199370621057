import { useCallback, useState } from 'react'

import Tracking from '~/utils/tracking'

export const useWalkthrough = () => {
  const [step, setStep] = useState<number | null>(null)

  const isDone = !!localStorage.getItem('@doris:walkthrough')

  const handleNavigate = useCallback(() => {
    setStep(prev => (prev ? ++prev : 1))

    step === 2 && localStorage.setItem('@doris:walkthrough', 'true')

    if (!step) return

    Tracking.logEvent('WALKMEIN', { step: step + 1, widget: true })
  }, [step])

  const handleSkip = useCallback(() => {
    localStorage.setItem('@doris:walkthrough', 'true')
    setStep(2)
  }, [])

  const handleRestart = useCallback(() => {
    localStorage.removeItem('@doris:walkthrough')
    setStep(0)
  }, [])

  return { step: step || 0, isDone, handleNavigate, handleSkip, handleRestart }
}

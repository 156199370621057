import React from 'react'

import { TIconProps } from '.'

const Robes: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <>
      <svg
        data-testid="icon-robe"
        style={{
          width: 'auto',
          height: size,
        }}
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.8096 0H20.3556L20.1779 0.38095C19.435 1.9619 18.1525 2.9841 16.6731 3.219V32H26.2667V7.9619L29.0287 9.9365L32.0001 4.4381L25.8096 0ZM19.9874 28.1524H18.654V25.9238H19.9874V28.1524ZM19.9874 23.6317H18.654V21.4032H19.9874V23.6317ZM19.9874 19.1111H18.654V16.8825H19.9874V19.1111ZM19.9874 14.5841H18.654V12.3556H19.9874V14.5841ZM19.9874 10.0635H18.654V7.8349H19.9874V10.0635Z"
          fill={color}
        />
        <path
          d="M15.3333 32V3.2254C13.8476 2.9905 12.5714 1.9619 11.8286 0.38095L11.6508 0H6.2095L0 4.4381L2.9714 9.9365L5.746 7.9492V32H15.3333ZM12.0127 7.8413H13.346V10.0698H12.0127V7.8413ZM12.0127 12.3619H13.346V14.5905H12.0127V12.3619ZM12.0127 16.8825H13.346V19.1111H12.0127V16.8825ZM12.0127 21.4032H13.346V23.6317H12.0127V21.4032ZM12.0127 25.9302H13.346V28.1587H12.0127V25.9302Z"
          fill={color}
        />
      </svg>
    </>
  )
}

export default Robes

import { useCallback } from 'react'

import { useLazyQuery } from '@apollo/client'

import { IDorisConfig } from '~/entities/DorisConfig'

import { GET_DORIS_CONFIG } from './graphql'
import { IQueryData, IQueryDorisConfig, IQueryVars } from './types'

export const useQueryDorisConfig = (): IQueryDorisConfig => {
  const [fetchDorisConfig, { data, called, loading, error }] = useLazyQuery<IQueryData, IQueryVars>(GET_DORIS_CONFIG)

  const fetch = useCallback(
    (partnerId: number): Promise<IDorisConfig[]> =>
      new Promise(async (resolve, reject) => {
        const { data, error } = await fetchDorisConfig({
          variables: { group_id: partnerId },
        })

        if (!data?.doris_config?.length || error) {
          reject(error || new Error('Config not found'))

          return
        }

        resolve(data.doris_config)
      }),
    [fetchDorisConfig],
  )

  return {
    fetch,
    data,
    called,
    loading,
    error,
  }
}

import { TCouponBait } from '~/entities/Coupon'

import { TRewardClaimed } from './types'

export const LOCAL_STORAGE_COUPON_FLOW = '@doris:coupon_flow'

export const markRewardAsClaimed = (type: TCouponBait['type']) => {
  const rewardClaimed: Required<TRewardClaimed> = {
    hasRewardBeenClaimed: {
      selfAvatar: false,
    },
  }

  rewardClaimed.hasRewardBeenClaimed[type] = true

  window.localStorage.setItem(LOCAL_STORAGE_COUPON_FLOW, JSON.stringify(rewardClaimed))
}

export const getCouponFlowLocalStorage = (): TRewardClaimed | null => {
  const storedData = window.localStorage.getItem(LOCAL_STORAGE_COUPON_FLOW)
  return storedData ? JSON.parse(storedData) : null
}

export const showCouponFlow = (type: TCouponBait['type']) => {
  const couponFlowLocalStorage = getCouponFlowLocalStorage()

  if (!couponFlowLocalStorage) {
    return true
  }

  if (couponFlowLocalStorage.hasRewardBeenClaimed && couponFlowLocalStorage.hasRewardBeenClaimed[type]) {
    return false
  }

  return true
}

import { FC, useCallback, useState } from 'react'

import { BackdropsContext } from '.'

export const BackdropsProvider: FC = ({ children }) => {
  const shouldNotDisplayBackdrop = !!localStorage.getItem('@doris:privacy_modal_viewed')

  const [isCombinedItemsVisible, setIsCombinedItemsVisible] = useState(false)
  const [isCombineVisible, setIsCombineVisible] = useState(false)
  const [isAlertSizing, setShowAlertSizing] = useState(false)
  const [isSettingsVisible, setIsSettingsVisible] = useState(false)
  const [isAlertMeasurements, setIsAlertMeasurements] = useState(false)
  const [isTakePictureModalVisible, setIsTakePictureModalVisible] = useState(false)
  const [isSendMeasurementsBackdropOpen, setIsSendMeasurementsBackdropOpen] = useState(false)
  const [isPrivacyDisclaimerVisible, setIsPrivacyDisclaimerVisible] = useState(!shouldNotDisplayBackdrop)

  const handleCombinedItemsState = (open: boolean) => {
    setIsCombinedItemsVisible(open)
  }

  const handleMeasurementsAlertOpen = () => {
    setIsAlertMeasurements(true)
  }

  const onOpenNewsletter = useCallback(() => {
    setIsCombineVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsCombinedItemsVisible])

  const value = {
    isCombinedItemsVisible,
    setIsCombinedItemsVisible,
    handleCombinedItemsState,
    isCombineVisible,
    setIsCombineVisible,
    isAlertSizing,
    setShowAlertSizing,
    isSettingsVisible,
    setIsSettingsVisible,
    isAlertMeasurements,
    setIsAlertMeasurements,
    handleMeasurementsAlertOpen,
    isTakePictureModalVisible,
    setIsTakePictureModalVisible,
    onOpenNewsletter,
    isSendMeasurementsBackdropOpen,
    setIsSendMeasurementsBackdropOpen,
    isPrivacyDisclaimerVisible,
    setIsPrivacyDisclaimerVisible,
  }

  return <BackdropsContext.Provider value={value}>{children}</BackdropsContext.Provider>
}

import { FC, useCallback, useEffect, useRef, useState } from 'react'

import { useCurrentModel } from '~/hooks'
import { useQueryCategories } from '~/hooks-queries'
import { useGetOutfit } from '~/hooks-queries/outfit'
import { useMeasurements } from '~/hooks/useMeasurements'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TStartTryon } from '~/hooks/useTryon/types'

import { useMeasurementsContext } from '~/context/Measurements'
import { useTryonContext } from '~/context/Tryon'
import { useWidgetState } from '~/context/WidgetState'

import { Backdrop } from '~/components/Backdrop'
import CategoryList from '~/components/CategoryList'
import { Icon } from '~/components/Icon'
import { IconButton } from '~/components/IconButton'
import { ProductsList } from '~/components/ProductsList'

import theme from '~/theme'

import { ICategoryItem, IProduct, TAgeGroup, TCategoryType, TGender } from '~/entities'
import { getProduct as getBaseProduct } from '~/utils/baseProduct'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { categoriesFilterWhere } from './buildWhereFilter'
import { ILastTryon, IProductsBackdropProps, TCreateTryon, TCurrentProductsMap } from './types'
import { PRODUCTS_PER_PAGE } from './utils'

export const ProductsBackdrop: FC<IProductsBackdropProps> = ({ active, handleState, isLoading }) => {
  const { getCurrentModel } = useCurrentModel()
  const currentModel = getCurrentModel()

  const { data: categoriesData, loading: loadingCategories } = useQueryCategories(
    categoriesFilterWhere({
      gender: currentModel.gender as TGender,
      age_group: currentModel.age_group as TAgeGroup,
    }),
  )
  const { fetch: fetchProducts, loading: loadingProducts, isLastPage } = useGetOutfit()
  const { stateCurrentTryon, setTryonState, clearStates: clearTryonStates } = useTryonContext()
  const { startTryon, removeTryon, finishTryonUpscale } = useTryon()
  const { clearStates: clearMeasurementsStates } = useMeasurementsContext()
  const { resetMeasurements } = useMeasurements()
  const { getPartner } = usePartner()
  const { shouldCallTryonFit, setShouldCallTryonFit } = useWidgetState()

  const [products, setProducts] = useState<IProduct[]>()
  const [componentDisabled, setComponentDisabled] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<ICategoryItem>()
  const [categoryListData, setCategoryListData] = useState<ICategoryItem[]>()
  const [lastTryon, setLastTryon] = useState<ILastTryon>()
  const [minimized, setMinimized] = useState(false)
  const [nextProductActive, setNextProductActive] = useState<IProduct>()
  const [page, setPage] = useState(0)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const currentProductsMap: TCurrentProductsMap = { ...stateCurrentTryon?.products }
  const categoryType = selectedCategory?.type.toLowerCase() as string
  const currentProductActive = currentProductsMap?.[categoryType]

  const currentProductByCategory = useRef<IProduct>()

  const clearCombineTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }

  const handleClear = useCallback(() => {
    removeTryon({ setState: clearTryonStates })
    resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsStates })

    clearCombineTimeout()
    Tracking.logEvent('COMBINE_CLEAN', {
      widget: true,
    })
  }, [clearMeasurementsStates, clearTryonStates, removeTryon, resetMeasurements])

  const getListCategories = useCallback(() => {
    setComponentDisabled(true)

    const startProduct = getBaseProduct()
    const categories = categoriesData?.filter(item => item.hasProducts && item.category).map(item => item.category)

    if (!categories?.length) {
      setCategoryListData([])
      return
    }

    const selected = startProduct ? { ...startProduct?.category, name: startProduct?.category.name } : categories[0]

    setCategoryListData(categories)
    setSelectedCategory({ ...selected })
  }, [categoriesData])

  const productsListReset = () => {
    setPage(0)
    setProducts(undefined)
  }

  const handleSelectCategory = (item: ICategoryItem) => {
    clearCombineTimeout()

    if (selectedCategory?.id === item.id) return

    setComponentDisabled(true)
    setSelectedCategory(item)
    productsListReset()
  }

  const handleClose = () => {
    handleState(false)
    clearCombineTimeout()
    setMinimized(false)
  }

  const handleGetCategoriesSelected = (lastTryon?: ILastTryon) => {
    const list: TCurrentProductsMap = {
      top: lastTryon?.top,
      bottom: lastTryon?.bottom,
      full: lastTryon?.full,
    }

    return Object.keys(list)
      .map(category => ({ id: list[category]?.category.id }))
      .filter(category => category?.id)
  }

  const getProducts = useCallback(
    async ({ category_id }: { category_id: number }) => {
      const data = await fetchProducts({
        limit: PRODUCTS_PER_PAGE,
        offset: page * PRODUCTS_PER_PAGE,
        category_id,
        identifier: currentProductByCategory.current?.identifier,
        gender: currentModel.gender as string as TGender,
        age_group: currentModel.age_group as string as TAgeGroup,
      })

      setProducts(prev => [...(prev ? prev : []), ...(data ? data : [])].filter(Boolean))
      setComponentDisabled(false)
    },
    [currentModel.gender, currentModel.age_group, fetchProducts, page],
  )

  const createTryon: TCreateTryon = async ({ product, category }) => {
    const { data: partner } = await getPartner()
    const isFullCategory = category === 'FULL'
    const data: TStartTryon['data'] = {
      from: 'combination',
      idModel: currentModel.id,
      products: {
        ...(!isFullCategory && { ...stateCurrentTryon?.products }),
        [category.toLowerCase()]: product,
      },
      isUnselect: !product,
      isAutomix: false,
      baseProduct: product && category,
      upscale: partner?.upscale,
    }

    if (!isFullCategory && data.products.full) {
      data.products.full = undefined
    }

    if (!data.products.top && !data.products.bottom && !data.products.full) {
      removeTryon({ setState: clearTryonStates })
      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsStates })

      Tracking.logEvent('TRY_ON', {
        avatar: currentModel.id,
        avatar_type: currentModel.type === 'SELF_MODEL' ? 'self' : 'pre',
        unselect: true,
        automix: false,
        start: false,
        widget: true,
      })

      return
    }

    setNextProductActive(product)

    if (partner?.upscale) {
      finishTryonUpscale()
    }

    startTryon({ data, setState: setTryonState })
  }

  const selectProduct = (product: IProduct) => {
    const category = selectedCategory?.type as TCategoryType
    const isChecked = currentProductActive?.id !== product?.id

    if (shouldCallTryonFit) setShouldCallTryonFit(false)
    clearCombineTimeout()
    createTryon({ product: isChecked ? product : undefined, category })

    if (isChecked) {
      timeoutRef.current = setTimeout(() => {
        setMinimized(true)
      }, 4000)
    }
  }

  const updateLastProducts = () => setLastTryon({ ...currentProductsMap, model: currentModel.id })

  const isTryonChange = () => {
    return (
      currentProductsMap?.top?.id !== lastTryon?.top?.id ||
      currentProductsMap?.bottom?.id !== lastTryon?.bottom?.id ||
      currentModel.id !== lastTryon?.model
    )
  }

  useEffect(() => {
    if (loadingCategories) return
    getListCategories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCategories])

  useEffect(() => {
    if (loadingCategories || !isTryonChange() || stateCurrentTryon?.origin === 'start') return

    productsListReset()
    getListCategories()
    updateLastProducts()
    setMinimized(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    setComponentDisabled(!!isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!selectedCategory) return

    const isActiveCategory = currentProductActive?.category.name === selectedCategory.name
    currentProductByCategory.current = undefined

    if (!currentProductByCategory.current && isActiveCategory) {
      currentProductByCategory.current = currentProductActive
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory])

  useEffect(() => {
    if (!selectedCategory) return

    getProducts({ category_id: selectedCategory.id })
  }, [getProducts, selectedCategory])

  return (
    <Backdrop
      visible={active}
      title={translate('PRODUCTS_BACKDROP_TITLE')}
      testID="backdrop-combine"
      handleClose={handleClose}
      layerIndex={4}
      primaryButton={
        <IconButton
          icon={<Icon name="broom" size="15px" color={theme.colors.primary} />}
          disabled={componentDisabled || !Object.keys(currentProductsMap)?.length}
          borderColor={theme.colors.primary}
          size={32}
          onClick={handleClear}
          testID="clear-button"
        />
      }
      minimized={minimized}
      handleMinimize={() => setMinimized(false)}
    >
      <Styled.Container>
        <CategoryList
          data={categoryListData}
          activeItem={selectedCategory}
          selectedItems={handleGetCategoriesSelected(currentProductsMap)}
          onClick={handleSelectCategory}
          disabled={componentDisabled}
        />

        <ProductsList
          data={products}
          selectedItem={isLoading ? nextProductActive : currentProductActive}
          showTag={!isLoading}
          currentPage={page}
          isLastPage={isLastPage}
          isLoading={loadingProducts}
          disabled={componentDisabled}
          onSelected={selectProduct}
          onNextPage={() => setPage(prev => prev + 1)}
        />
      </Styled.Container>
    </Backdrop>
  )
}

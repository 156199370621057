import { useContextSelector } from 'use-context-selector'

import { CouponFlowsContext } from './context'

export const useCouponFlowsContext = () => {
  const isBaitVisible = useContextSelector(CouponFlowsContext, state => state.isBaitVisible)
  const setIsBaitVisible = useContextSelector(CouponFlowsContext, state => state.setIsBaitVisible)
  const rewardToClaim = useContextSelector(CouponFlowsContext, state => state.rewardToClaim)
  const setRewardToClaim = useContextSelector(CouponFlowsContext, state => state.setRewardToClaim)

  return {
    isBaitVisible,
    setIsBaitVisible,
    rewardToClaim,
    setRewardToClaim,
  }
}

import { useEffect } from 'react'

import { useQueryModels } from '~/hooks-queries'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { usePartner } from '~/hooks/usePartner'

import { useWidgetState } from '~/context/WidgetState'

import { Splash } from '~/components/Splash'

import { Sentry } from '~/clients/sentry'
import { IPartner } from '~/entities'
import { enableSizingInfoModal } from '~/screens/Home/utils'

export const SplashScreen = () => {
  const { setReady } = useWidgetState()
  const { getDefaultModels, setDefaultModels, setDefaultModelsAsArray } = useCurrentModel()
  const { fetchModels } = useQueryModels()
  const { getPartner, setPartner } = usePartner()

  useEffect(() => {
    const fetchPartner = async () => {
      const { data, status, message } = await getPartner()

      if (!status) {
        // eslint-disable-next-line no-console
        console.error(message)

        return
      }

      setPartner({ partner: data as IPartner })
    }

    fetchPartner()
  }, [getPartner, setPartner])

  useEffect(() => {
    const models = getDefaultModels()

    if (models) {
      setReady()

      return
    }

    const fetch = async () => {
      try {
        const response = await fetchModels({ default_model: true })

        if (!response.length) throw new Error('O parceiro não contém modelos default')

        setDefaultModels(
          response.map(model => ({
            id: model.details.id,
            stage_image: model.details.stage_image,
            gender: model.details.gender,
            age_group: model.details.age_group,
            skin: model.details.skin_tone_id,
            type: 'PRE_MODEL',
          })),
        )

        setDefaultModelsAsArray(
          response.map(model => ({
            id: model.details.id,
            stage_image: model.details.stage_image,
            gender: model.details.gender,
            age_group: model.details.age_group,
            skin: model.details.skin_tone_id,
            type: 'PRE_MODEL',
          })),
        )

        setReady()
      } catch (error) {
        if (error?.graphQLErrors && error.graphQLErrors[0].extensions.code === 'access-denied') {
          Sentry.captureException({
            errorName: 'ACCESS_DENIED_FROM_PROVIDED_API_KEY',
            errorMessage: 'Acesso negado para a ApiKey informada',
            filePath: 'src/screens/SplashScreen/index.tsx',
            functionName: 'fetch',
          })
        }

        // eslint-disable-next-line no-console
        console.error(
          `[ERROR][MODELS]: ${error?.graphQLErrors ? error.graphQLErrors[0].extensions.code : error.message}`,
        )
      }
    }

    fetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDefaultModels, fetchModels, setReady, setDefaultModels])

  useEffect(() => {
    enableSizingInfoModal()
  }, [])

  return <Splash />
}

import { FC, useCallback } from 'react'

import { useCurrentModel } from '~/hooks'

import { useBackdropsContext } from '~/context/Backdrops'
import { CombinedItemsProvider } from '~/context/CombinedItems'
import { useNavigation } from '~/context/Navigation'
import { useTryonContext } from '~/context/Tryon'

import { NewsletterContainer } from '~/containers/NewsletterContainer'

import { AlertSizing } from '~/components/AlertSizing'
import { BackdropSizing } from '~/components/BackdropSizing'
import { CombinedItemsBackdrop } from '~/components/CombinedItemsBackdrop'
import CouponFlowsBackdrop from '~/components/CouponFlowsBackdrop'
import { PrivacyDisclaimerBackdrop } from '~/components/PrivacyDisclaimerBackdrop'
import { ProductsBackdrop } from '~/components/ProductsBackdrop'
import SettingsBackdrop from '~/components/SettingsBackdrop'
import { UpscaleTag } from '~/components/UpscaleTag'

import { SetMeasurementsBackdrop } from '~/modules/Sizing/components/SetMeasurementsBackdrop'

import { MobileUtils } from '~/utils/mobileUtils'
import { PostHogUtil } from '~/utils/postHog'
import Tracking from '~/utils/tracking'

import { getModalVideoPath, setSizingInfoModalAsSkipped } from '../../utils'
import { SizingTipModal } from '../SizingTipModal'
import { IStageBackdrops } from './types'

export const StageBackdrops: FC<IStageBackdrops> = ({
  screenLoading,
  handleAlertSizing,
  handleWalkthroughRestart,
  sizingModalOpeningSource,
  setIsMeasurementUpdatedManually,
}) => {
  const {
    isCombinedItemsVisible,
    handleCombinedItemsState,
    isCombineVisible,
    setIsCombineVisible,
    isAlertSizing,
    isSettingsVisible,
    setIsSettingsVisible,
    isAlertMeasurements,
    setIsAlertMeasurements,
    isTakePictureModalVisible,
    setIsTakePictureModalVisible,
    onOpenNewsletter,
    isSendMeasurementsBackdropOpen,
    setIsSendMeasurementsBackdropOpen,
  } = useBackdropsContext()

  const { stateTryon, stateCurrentTryon, stateTryonUpscale } = useTryonContext()

  const { getCurrentModel } = useCurrentModel()
  const currentModelInformation = getCurrentModel()

  const { navigate } = useNavigation()

  const IS_NEWSLETTER_FLAG_ENABLED = PostHogUtil.isFlagEnabled('NEWSLETTER_CONTENT')
  const IS_COUPON_FLOW_FLAG_ENABLED = PostHogUtil.isFlagEnabled('COUPON_FLOW')
  const IS_PRIVACY_DISCLAIMER_FLAG_ENABLED = PostHogUtil.isFlagEnabled('PRIVACY_DISCLAIMER')

  const handleWalkthrough = () => {
    setIsSettingsVisible(false)
    handleWalkthroughRestart()
  }

  const handleMeasurementsAlertRedoPhotoButton = () => navigate('Tips')

  const handleMeasurementsAlertButtonLater = () => setIsAlertMeasurements(false)

  const onModalPrimaryButtonClick = () => {
    setSizingInfoModalAsSkipped()
    setIsTakePictureModalVisible(false)

    if (MobileUtils.isMobile()) {
      navigate('InformData')
      return
    }

    navigate('Models', { modelsTab: 'SELF' })
  }

  const handleSkipSizingInfoModal = useCallback(() => {
    Tracking.logEvent('TOOLTIP', {
      origin: sizingModalOpeningSource,
      action: 'close',
      widget: true,
    })
    setIsTakePictureModalVisible(false)
    setSizingInfoModalAsSkipped()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizingModalOpeningSource])

  return (
    <>
      <CombinedItemsProvider>
        <CombinedItemsBackdrop
          active={isCombinedItemsVisible}
          handleClose={() => handleCombinedItemsState(false)}
          products={{
            top: stateCurrentTryon?.products?.top?.identifier,
            bottom: stateCurrentTryon?.products?.bottom?.identifier,
            full: stateCurrentTryon?.products?.full?.identifier,
          }}
        />
      </CombinedItemsProvider>

      <ProductsBackdrop active={isCombineVisible} handleState={setIsCombineVisible} isLoading={screenLoading} />

      <AlertSizing visible={isAlertSizing} onClick={handleAlertSizing} />

      <SettingsBackdrop
        active={isSettingsVisible}
        handleState={setIsSettingsVisible}
        handleWalkthrough={handleWalkthrough}
      />

      <BackdropSizing
        visible={isAlertMeasurements}
        outlinedButtonClick={handleMeasurementsAlertButtonLater}
        primaryButtonClick={handleMeasurementsAlertRedoPhotoButton}
      />

      <SizingTipModal
        isVisible={isTakePictureModalVisible}
        primaryButtonClick={onModalPrimaryButtonClick}
        secondaryButtonClick={() => {
          setIsTakePictureModalVisible(false)
          handleSkipSizingInfoModal()
        }}
        videoUrl={getModalVideoPath({ gender: currentModelInformation.gender })}
      />

      <NewsletterContainer
        onClickSnackBar={onOpenNewsletter}
        blockingState={stateTryon?.isLoading || screenLoading || !IS_NEWSLETTER_FLAG_ENABLED}
      />

      {IS_PRIVACY_DISCLAIMER_FLAG_ENABLED && <PrivacyDisclaimerBackdrop />}

      {IS_COUPON_FLOW_FLAG_ENABLED && <CouponFlowsBackdrop />}

      <SetMeasurementsBackdrop
        visible={isSendMeasurementsBackdropOpen}
        handleClose={() => setIsSendMeasurementsBackdropOpen(false)}
        onSuccessCallback={() => {
          localStorage.setItem('@doris:child-form-filled', JSON.stringify(true))
          setIsMeasurementUpdatedManually()
        }}
      />

      {!screenLoading && stateTryonUpscale?.isLoading && <UpscaleTag />}
    </>
  )
}

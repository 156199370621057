import { FC, Fragment, useEffect, useMemo, useState } from 'react'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { copyToClipboard } from '~/utils/copyToClipboard'
import tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from '../styles'
import { IRewardBackdropProps } from './types'

const DELAY = {
  CLIPBOARD_ACTION_FEEDBACK: 150,
  CLIPBOARD_ACTION_SUCCESS: 2000,
}

const RewardBackdrop: FC<IRewardBackdropProps> = ({ visible, title, text, code, close }) => {
  const [copied, setCopied] = useState(false)

  const parts = useMemo(() => title.split('{{code}}'), [title])

  const handleCopyToClipboard = () => {
    setTimeout(() => {
      copyToClipboard(code)
      setCopied(true)

      tracking.logEvent('COUPON_COPIED', { widget: true })
    }, DELAY.CLIPBOARD_ACTION_FEEDBACK)

    setTimeout(() => {
      close()
    }, DELAY.CLIPBOARD_ACTION_SUCCESS)
  }

  useEffect(() => {
    if (visible) {
      tracking.logEvent('COUPON_DELIVERED', { widget: true })
    }
  }, [visible])

  return (
    <Backdrop testID="reward-backdrop" visible={visible}>
      <Styled.Container>
        <Styled.ContainerIcon>
          <Icon name="tshirt" size="24px" color={theme.colors.primary} />
        </Styled.ContainerIcon>

        <Styled.TitleSizing align="center">
          {parts.map((part, index) => (
            <Fragment key={`reward-title-part-${index}`}>
              {part}
              {index < parts.length - 1 && <Styled.CodeHighlight>{code}</Styled.CodeHighlight>}
            </Fragment>
          ))}
        </Styled.TitleSizing>
        <Styled.Text align="center">{text}</Styled.Text>
        <Styled.ContainerButton>
          <TextButton testID="reward-backdrop-close-button" variant="outlined" onClick={close}>
            {translate('BACKDROP_PRIVACY_DISCLAIMER_DISMISS_BUTTON')}
          </TextButton>
          {!copied ? (
            <TextButton testID="reward-backdrop-cta-button" onClick={handleCopyToClipboard}>
              {translate('BACKDROP_REWARD_CALL_TO_ACTION_BUTTON')}
            </TextButton>
          ) : (
            <TextButton
              testID="reward-backdrop-feedback-button"
              onClick={handleCopyToClipboard}
              background={theme.colors.status.success}
            >
              {translate('BACKDROP_REWARD_CALL_TO_ACTION_FEEDBACK_BUTTON')}
            </TextButton>
          )}
        </Styled.ContainerButton>
      </Styled.Container>
    </Backdrop>
  )
}

export default RewardBackdrop

import { FC, useEffect, useState } from 'react'

import { useNavigation } from '~/context/Navigation'

import { Backdrop } from '~/components/Backdrop'
import { Icon } from '~/components/Icon'
import { TextButton } from '~/components/TextButton'

import theme from '~/theme'

import { MobileUtils } from '~/utils/mobileUtils'
import tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from '../styles'
import { IBaitBackdropProps } from './types'

const BaitBackdrop: FC<IBaitBackdropProps> = ({ visible, title, text, close }) => {
  const [navigateToCreateSelfPhoto, setNavigateToCreateSelfPhoto] = useState(false)
  const { navigate } = useNavigation()

  useEffect(() => {
    // Close bait backdrop before navigation unmounts the component
    if (navigateToCreateSelfPhoto) {
      tracking.logEvent('AVATAR_ADD', { widget: true, origin: 'coupon' })

      if (MobileUtils.isMobile()) {
        navigate('InformData')
        return
      }

      navigate('Models', { modelsTab: 'SELF' })
    }
  }, [navigate, navigateToCreateSelfPhoto])

  useEffect(() => {
    if (visible) {
      tracking.logEvent('COUPON_OPEN', { widget: true })
    }
  }, [visible])

  return (
    <Backdrop testID="bait-backdrop" visible={visible}>
      <Styled.Container>
        <Styled.ContainerIcon>
          <Icon name="tshirt" size="24px" color={theme.colors.primary} />
        </Styled.ContainerIcon>
        <Styled.TitleSizing align="center">{title}</Styled.TitleSizing>
        <Styled.Text align="center">{text}</Styled.Text>
        <Styled.ContainerButton>
          <TextButton testID="bait-backdrop-close-button" variant="outlined" onClick={close}>
            {translate('WALKTHROUGH_SKIP_BUTTON_TEXT')}
          </TextButton>
          <TextButton
            testID="bait-backdrop-cta-button"
            onClick={() => {
              close()
              setNavigateToCreateSelfPhoto(true)
            }}
          >
            {translate('BACKDROP_BAIT_CALL_TO_ACTION_BUTTON')}
          </TextButton>
        </Styled.ContainerButton>
      </Styled.Container>
    </Backdrop>
  )
}

export default BaitBackdrop

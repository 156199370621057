import React, { CSSProperties } from 'react'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { sizingMessagesColorsMap } from './constants'
import { ESizingColumnKeys, ISizeDataDetail, ISizingData, TSizingMessage } from './types'

export interface ISizingTableProps {
  data: ISizingData
  styles?: CSSProperties
}

export const SizingTable = ({ data, styles }: ISizingTableProps) => {
  const columnKeys = Object.values(ESizingColumnKeys).filter(
    key => key in data.size[0].data && data.size[0].data[key] !== null,
  )
  return (
    <Styled.Container style={styles} data-testid="sizing-table-container">
      <Styled.Table data-testid="sizing-table">
        <thead>
          <tr>
            {columnKeys.map(
              key =>
                key in data.size[0].data && (
                  <Styled.TableHeader data-testid={`sizing-table-${key}-header`} key={key}>
                    {translate(key.toUpperCase() as keyof typeof translate)}
                  </Styled.TableHeader>
                ),
            )}
          </tr>
        </thead>
        <tbody>
          {data.size.map((item, index) => (
            <Styled.TableRow
              key={index}
              data-testid={`sizing-table-row-${index}`}
              color={sizingMessagesColorsMap[item.status as TSizingMessage]}
            >
              {columnKeys.map(
                key =>
                  key in item.data &&
                  (key === ESizingColumnKeys.SIZE ? (
                    <Styled.TableCellBold
                      data-testid={`sizing-table-${key}-${index}`}
                      key={key}
                      color={sizingMessagesColorsMap[item.status as TSizingMessage]}
                    >
                      {item.data[key.toLowerCase() as keyof ISizeDataDetail]}
                    </Styled.TableCellBold>
                  ) : (
                    <Styled.TableCell data-testid={`sizing-table-${key}-${index}`} key={key}>
                      {item.data[key.toLowerCase() as keyof ISizeDataDetail]}
                    </Styled.TableCell>
                  )),
              )}
            </Styled.TableRow>
          ))}
        </tbody>
      </Styled.Table>
    </Styled.Container>
  )
}

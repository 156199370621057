import { FC, useCallback, useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { useSubscriptionProcessingAvatar } from '~/hooks/useAvatar/useSubscriptionProcessingAvatar'
import { useSubscriptionProcessingInfos } from '~/hooks/useAvatar/useSubscriptionProcessingInfos'
import { useSubscriptionProcessingSizing } from '~/hooks/useAvatar/useSubscriptionProcessingSizing'
import { useMeasurements } from '~/hooks/useMeasurements'
import { MeasurementsStorageInstance } from '~/hooks/useMeasurements/storage'
import { usePartner } from '~/hooks/usePartner'
import { useTryon } from '~/hooks/useTryon'
import { TryonStorageInstance } from '~/hooks/useTryon/storage'

import { useAvatarContext } from '~/context/Avatar'
import { useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'
import { useTryonContext } from '~/context/Tryon'

import { IProcessingScreenProps } from '~/screens/ProcessingScreen/types'
import { BrowserUrlApi } from '~/utils/browserUrlApi'
import Tracking from '~/utils/tracking'

import { getSentences } from './constants'

export const withAvatarTimeoutProcessingScreen = (ProcessingScreen: FC<IProcessingScreenProps>) => {
  const Component: React.FC = () => {
    const { navigate, params } = useNavigation()
    const { createAvatar } = useAvatar()
    const { setAvatarState, stateAvatar, clearStates } = useAvatarContext()
    const { resetMeasurements } = useMeasurements()
    const { clearStates: clearMeasurementsState } = useMeasurementsContext()
    const { getPartner } = usePartner()

    const { avatarProcessedStatus, subscriptionCreateAvatar } = useSubscriptionProcessingAvatar()
    const { subscriptionSizing, sizingProcessedStatus } = useSubscriptionProcessingSizing()
    const { subscriptionInfos, infosProcessedStatus } = useSubscriptionProcessingInfos()

    const { startTryon } = useTryon()
    const { stateTryon, setTryonState } = useTryonContext()
    const { resetAvatar } = useAvatar()

    const [allProcessed, setAllProcessed] = useState(false)

    const onScreenInit = useCallback(() => {
      const slotList = params?.base64SlotList as Array<string>

      createAvatar({ data: slotList[0], setState: setAvatarState })
    }, [params, createAvatar, setAvatarState])

    const onCreateAvatarInit = useCallback(() => {
      if (!stateAvatar?.isLoading || avatarProcessedStatus) return

      subscriptionCreateAvatar()
    }, [stateAvatar?.isLoading, avatarProcessedStatus, subscriptionCreateAvatar])

    const onAvatarProcessed = useCallback(() => {
      const currentTryon = TryonStorageInstance.get()
      const wasTryonNotCalledOrExternalUser = currentTryon && (!stateTryon?.called || BrowserUrlApi.isExternalUser())

      if (!avatarProcessedStatus) return

      if (wasTryonNotCalledOrExternalUser) {
        const start = async () => {
          const { data: partner } = await getPartner()
          startTryon({
            data: {
              idModel: stateAvatar?.data?.avatar_uuid as string,
              products: currentTryon,
              from: 'avatar',
              upscale: partner?.upscale,
            },
            setState: setTryonState,
          })
        }

        start()
      }

      resetMeasurements({ data: ['stateCurrentMeasurements'], setState: clearMeasurementsState })

      subscriptionSizing()
    }, [
      avatarProcessedStatus,
      setTryonState,
      startTryon,
      stateAvatar?.data?.avatar_uuid,
      stateTryon?.called,
      subscriptionSizing,
      clearMeasurementsState,
      resetMeasurements,
      getPartner,
    ])

    const onSizingProcessed = useCallback(() => {
      if (!sizingProcessedStatus.ended) return

      if (sizingProcessedStatus.error) {
        setAllProcessed(true)

        return
      }

      subscriptionInfos()
    }, [sizingProcessedStatus, subscriptionInfos])

    const onInfosProcessed = useCallback(() => {
      if (!infosProcessedStatus.ended) return

      setAllProcessed(true)
    }, [infosProcessedStatus.ended])

    const onProcessingFinished = useCallback(() => {
      if (!allProcessed) return

      if (sizingProcessedStatus.error || infosProcessedStatus.error) {
        MeasurementsStorageInstance.set({ avatar_uuid: stateAvatar?.data?.avatar_uuid as string, showAlert: true })

        Tracking.logEvent('AVATAR_ERROR', {
          error: 'A97',
          gender: stateAvatar?.data?.gender,
          height: stateAvatar?.data?.height,
          weight: stateAvatar?.data?.weight,
          age: stateAvatar?.data?.age,
          widget: true,
        })
      }

      resetAvatar({ data: ['stateAvatar'], setState: clearStates })

      navigate('Home', { isCombineActive: true })
    }, [
      allProcessed,
      sizingProcessedStatus.error,
      infosProcessedStatus.error,
      stateAvatar?.data,
      resetAvatar,
      clearStates,
      navigate,
    ])

    useEffect(onScreenInit, [onScreenInit])

    useEffect(onCreateAvatarInit, [onCreateAvatarInit])

    useEffect(onAvatarProcessed, [onAvatarProcessed])

    useEffect(onSizingProcessed, [onSizingProcessed])

    useEffect(onInfosProcessed, [onInfosProcessed])

    useEffect(onProcessingFinished, [onProcessingFinished])

    const sentences = getSentences()

    return <ProcessingScreen sentences={sentences} closeUrl="Models" />
  }

  Component.displayName = 'withAvatarTimeoutProcessing'

  return Component
}

import { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { useGetOutfit } from '~/hooks-queries/outfit'
import { useCurrentModel } from '~/hooks/useCurrentModel'
import { usePartner } from '~/hooks/usePartner'
import { getType } from '~/hooks/usePartner/utils'

import { useCombinedItems } from '~/context/CombinedItems'
import { IProductCombine } from '~/context/CombinedItems/types'
import { useHandleBuyButton } from '~/context/HandleBuyButton'
import { TCurrentMeasurementsState, useMeasurementsContext } from '~/context/Measurements'
import { useNavigation } from '~/context/Navigation'

import { Backdrop } from '~/components/Backdrop'
import { CartFeedbackBackdrop } from '~/components/Backdrop/CartFeedbackBackdrop'
import Box from '~/components/Box'
import CartButton, { TCartButtonType } from '~/components/CartButton'
import { CombinedProductList } from '~/components/CombinedProductList'
import TotalPrice from '~/components/TotalPrice'

import theme from '~/theme'

import { IPartner, IProduct, TCategoryType } from '~/entities'
import { HandleGoToCartInstance } from '~/utils/handleGoToCart'
import { getPerfectSize, getPerfectStock } from '~/utils/sizing'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'

import * as Styled from './styles'
import Typography from '../Typography'
import { ICombinedItemsBackdropProps } from './types'
import { generateHandleBuyButtonPayload } from './utils/generateHandleBuyButtonPayload'
import { getProductSizeInformation } from './utils/getProductSizeInformation'

export const CombinedItemsBackdrop: FC<ICombinedItemsBackdropProps> = ({ active, handleClose, products }) => {
  const [currentProducts, setCurrentProducts] = useState<IProductCombine[]>([])
  const [isUpdatingTotalPrice, setIsUpdatingTotalPrice] = useState<boolean>(false)
  const [cartButtonType, setCartButtonType] = useState<TCartButtonType | null>()
  const { handleBuyButtonRef } = useHandleBuyButton()
  const { sendMessage, getPartner, getPartnerFlags } = usePartner()
  const { selectedProducts, handleSetSelectedProducts, selectedSizes, handleSelectProductSize } = useCombinedItems()
  const { getCurrentModel } = useCurrentModel()
  const { stateCurrentMeasurements, stateMeasurements } = useMeasurementsContext()
  const [productsData, setProductsData] = useState<Array<IProduct>>([])
  const [visibleFeedbackAddCart, setVisibleFeedbackAddCart] = useState(false)
  const [partner, setPartner] = useState<IPartner>()

  const { fetch: fetchProducts, loading } = useGetOutfit()

  const { navigate } = useNavigation()

  const hidePriceFlag = getPartnerFlags().some(flag => flag.path === 'hide_price')

  const getProducts = useCallback(async () => {
    const identifiers = Object.values(products).filter(Boolean).map(String)
    if (identifiers.length === 0) return

    const data = await fetchProducts({
      limit: identifiers.length,
      ...(identifiers.length > 1 ? { identifiers } : { identifier: identifiers[0] }),
    })

    if (!data || !data.length) return

    setProductsData(data)
  }, [fetchProducts, products])

  const totalPrice = useMemo(
    () =>
      selectedProducts
        .map(product => product.price)
        .reduce((previousPrice, currentPrice) => previousPrice + currentPrice, 0),
    [selectedProducts],
  )

  const productsWithoutPrice = useMemo(() => selectedProducts.filter(({ price }) => !price), [selectedProducts])
  const disableButton = useMemo(
    () => Object.values(selectedSizes).length !== selectedProducts.length || !Object.values(selectedProducts).length,
    [selectedProducts, selectedSizes],
  )
  const topOrFullProductFilter = useMemo(
    () => currentProducts.filter(product => product.category.type === 'TOP' || product.category.type === 'FULL'),
    [currentProducts],
  )
  const topOrFullProduct = useMemo(
    () => (topOrFullProductFilter.length ? topOrFullProductFilter[0] : null),
    [topOrFullProductFilter],
  )
  const bottomProductFilter = useMemo(
    () => currentProducts.filter(product => product.category.type === 'BOTTOM'),
    [currentProducts],
  )
  const bottomProduct = useMemo(
    () => (bottomProductFilter.length ? bottomProductFilter[0] : null),
    [bottomProductFilter],
  )

  const setLogEventCartConfirm = (confirmed: boolean) => {
    Tracking.logEvent('CART_CONFIRM', {
      confirmed: confirmed,
      total: totalPrice,
      qtdcheck: selectedProducts.length,
      qtdtotal: currentProducts.length,
      widget: true,
      avatar: getCurrentModel().id,
      top_sku: topOrFullProduct?.identifier || null,
      top_size: topOrFullProduct
        ? getProductSizeInformation({ product: topOrFullProduct, cartSelectedSizes: selectedSizes }).size
        : null,
      top_size_tag: topOrFullProduct?.measurement?.selected?.fit_summary || null,
      top_perfect_stock: getPerfectStock(stateCurrentMeasurements, topOrFullProduct?.category.type),
      bot_sku: bottomProduct?.identifier || null,
      bot_size: bottomProduct
        ? getProductSizeInformation({ product: bottomProduct, cartSelectedSizes: selectedSizes }).size
        : null,
      bot_size_tag: bottomProduct?.measurement?.selected?.fit_summary || null,
      bot_perfect_stock: getPerfectStock(stateCurrentMeasurements, bottomProduct?.category.type),
    })
  }

  const handleButton = async () => {
    if (cartButtonType === 'shop') {
      const result =
        handleBuyButtonRef?.current &&
        (await handleBuyButtonRef.current(
          Object.values(selectedSizes),
          generateHandleBuyButtonPayload({ selectedSizes, selectedProducts }),
        ))

      if (!result && result !== undefined) {
        setLogEventCartConfirm(false)
        return
      }

      setLogEventCartConfirm(true)
      setVisibleFeedbackAddCart(true)

      return
    }

    sendMessage({
      products: selectedProducts.map(({ identifier, name, product_options }) => ({
        identifier,
        name,
        selectedSize: product_options?.find(options => options.unique_code == selectedSizes[identifier])?.partner_size
          ?.name,
      })),
    })
  }

  const goToUrl = (url: string) => {
    window.location.href = url
  }

  const handleGoToCartButton = async () => {
    const { data } = await getPartner()

    const url = data?.cart_url as string

    HandleGoToCartInstance.hasRef() ? HandleGoToCartInstance.call() : goToUrl(url)
  }

  const handleSizing = ({ category }: { category: TCategoryType }): void => {
    Tracking.logEvent('SIZE_OPEN', {
      avatar: getCurrentModel().id,
      bot_perfect_size: bottomProduct && getPerfectSize(stateCurrentMeasurements, bottomProduct?.category.type),
      bot_perfect_stock: bottomProduct && getPerfectStock(stateCurrentMeasurements, bottomProduct?.category.type),
      bottom: bottomProduct?.identifier || null,
      origin: 'cart',
      return: true,
      top: topOrFullProduct?.identifier || null,
      top_perfect_size: topOrFullProduct && getPerfectSize(stateCurrentMeasurements, topOrFullProduct.category.type),
      top_perfect_stock: topOrFullProduct && getPerfectStock(stateCurrentMeasurements, topOrFullProduct?.category.type),
      type_avatar: 'self',
      widget: true,
    })
    navigate('SizingScreen', { states: { measurementsTab: category } })
  }

  const getCartButtonType = useCallback(async () => {
    if (handleBuyButtonRef?.current) {
      return 'shop'
    }

    const { data } = await getPartner()

    return getType({ email: data?.email, whatsapp: data?.whatsapp })
  }, [getPartner, handleBuyButtonRef])

  const closeBackDropAndChangeViewToCart = () => {
    handleClose()
    setTimeout(() => {
      setVisibleFeedbackAddCart(false)
    }, 500)
  }

  useEffect(() => {
    setIsUpdatingTotalPrice(true)

    setTimeout(() => {
      setIsUpdatingTotalPrice(false)
    }, 500)
  }, [totalPrice])

  useEffect(() => {
    const fetchCartButtonType = async () => {
      setCartButtonType(await getCartButtonType())
    }
    const fetchCartUrl = async () => {
      setPartner((await getPartner()).data)
    }

    fetchCartButtonType()
    fetchCartUrl()
  }, [handleBuyButtonRef, setCartButtonType, getCartButtonType, getPartner])

  useEffect(() => {
    if (!active) {
      setProductsData([])
      return
    }

    setCurrentProducts([])
    getProducts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])

  useEffect(() => {
    if (!productsData.length || !active || stateMeasurements?.isLoading) return

    const products: Array<IProductCombine> = productsData.map(({ selling_price, list_price, ...rest }) => ({
      price: !rest?.product_options?.length ? 0 : selling_price || list_price || 0,
      ...rest,
    }))

    const selectedProductSize: Array<{ identifier: string; unique_code: string }> = []

    if (stateCurrentMeasurements?.configs?.showButton && stateCurrentMeasurements?.configs?.type !== 'inactive') {
      products.forEach(product => {
        Object.keys(stateCurrentMeasurements?.products as TCurrentMeasurementsState['products']).forEach(category => {
          if (product.id !== stateCurrentMeasurements?.products[category]?.id) return

          product.measurement = {
            status: true,
            category: category.toUpperCase() as TCategoryType,
          }

          const selectedMeasurement = stateCurrentMeasurements?.products[category]?.measurements.find(
            item => item.selected,
          )

          if (!selectedMeasurement) return

          const selectedSize = {
            identifier: product.identifier,
            unique_code: product.product_options?.find(
              option => option.partner_size?.name === selectedMeasurement.label,
            )?.unique_code as string,
          }

          product.measurement = {
            ...product.measurement,
            selected: selectedMeasurement,
          }

          selectedProductSize.push({ identifier: selectedSize.identifier, unique_code: selectedSize.unique_code })
        })
      })
    }

    handleSetSelectedProducts(products)

    if (selectedProductSize.length) {
      handleSelectProductSize(selectedProductSize)
    }

    setCurrentProducts(products)

    // Add this rule because we need execute just one time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsData,
    active,
    stateCurrentMeasurements?.configs?.showButton,
    stateCurrentMeasurements?.configs?.type,
    stateMeasurements?.isLoading,
  ])

  return (
    <Backdrop
      visible={Boolean(active)}
      handleClose={closeBackDropAndChangeViewToCart}
      title={translate('COMBINED_ITEMS_TITLE')}
      withBackground
      preventOverflow
      layerIndex={4}
    >
      {visibleFeedbackAddCart && (
        <CartFeedbackBackdrop
          onClickGoToCart={handleGoToCartButton}
          visible={true}
          onClickKeepBuying={closeBackDropAndChangeViewToCart}
          hasCartUrl={!!partner?.cart_url}
        />
      )}

      <Styled.Container>
        <Typography
          type="paragraph"
          color={theme.colors.textSecondary}
          testID="combined-itens-backdrop-subtitle"
          align="center"
        >
          {cartButtonType ? translate('COMBINED_ITEMS_DESCRIPTION') : translate('COMBINED_ITEMS_DESCRIPTION_NO_CART')}
        </Typography>
        <CombinedProductList
          handleSizing={handleSizing}
          isLoading={loading}
          skeletonsCount={Object.values(products).filter(product => product).length}
          data={currentProducts}
          hasCart={cartButtonType}
        />
        {cartButtonType && (
          <Box
            padding="0 0 0 20px"
            borderRadius="999px"
            backgroundColor={theme.colors.palette.gray95}
            isLoading={loading || isUpdatingTotalPrice}
          >
            <Styled.Column data-testid="column-actions" alignItems="center" justifyContent="space-between">
              <TotalPrice value={totalPrice} showMessage={!!productsWithoutPrice.length || hidePriceFlag} />
              <CartButton type={cartButtonType} onClick={handleButton} disabled={disableButton} />
            </Styled.Column>
          </Box>
        )}
      </Styled.Container>
    </Backdrop>
  )
}

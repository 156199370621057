import React from 'react'

import { TIconProps } from '.'

const TankTop: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      data-testid="icon-tank-top"
      width="28"
      height="32"
      viewBox="0 0 28 32"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <path
        d="M4.8487 0L3.7385 0.55428C3.312 0.76793 3.0822 1.24183 3.1793 1.70888C4.4748 7.9383 2.0905 11.2487 0.246701 13.4408C0.0872011 13.6307 -0.000299231 13.8704 7.68869e-07 14.1184C7.68869e-07 21.4815 3.1563 22.7304 3.1563 26.7862V30.9474C3.1563 31.5283 3.628 31.9999 4.2089 32H23.1414C23.7224 31.9999 24.194 31.5283 24.1941 30.9474V26.7401C24.1941 22.5326 27.3487 21.4815 27.3487 14.1184C27.349 13.8704 27.2631 13.6307 27.1036 13.4408C25.2599 11.2487 22.8755 7.9383 24.1711 1.70888C24.2681 1.24183 24.0383 0.76793 23.6118 0.55428L22.5016 0C22.2877 0.97049 21.737 3.1764 20.7286 5.3947C20.0548 6.8771 19.177 8.374 18.0247 9.5263C16.8723 10.6787 15.4129 11.4885 13.6743 11.4885C11.9358 11.4885 10.478 10.6787 9.3257 9.5263C8.1733 8.374 7.2955 6.8771 6.6217 5.3947C5.6134 3.1764 5.0626 0.97049 4.8487 0ZM7.7747 5.8174C8.3877 7.0891 9.148 8.3224 10.0691 9.2434C11.0862 10.2605 12.2572 10.898 13.6743 10.898C15.0914 10.898 16.2641 10.2605 17.2813 9.2434C18.2023 8.3224 18.9626 7.0891 19.5757 5.8174C18.5602 6.8296 17.3027 7.5651 15.9227 7.9539C15.2972 8.1573 14.5616 8.2681 13.6743 8.2681C12.7871 8.2681 12.0531 8.1573 11.4276 7.9539C10.0476 7.5651 8.7901 6.8296 7.7747 5.8174Z"
        fill={color}
      />
    </svg>
  )
}

export default TankTop

import { gql } from '@apollo/client'

const PRODUCT_FIELDS = gql`
  fragment productFields on product {
    id
    identifier
    parent_identifier
    seller_ids
    thumbnail
    name
    url
    selling_price
    list_price
    outfit_uuid
    gender
    age_group
    category {
      id
      name
      type
    }
    product_options(order_by: { partner_size: { order: asc, id: asc } }) {
      has_stock
      unique_code
      partner_size {
        id
        name
        order
      }
    }
  }
`

export const GET_PRODUCTS_COMBINE = gql`
  ${PRODUCT_FIELDS}

  query product($offset: Int, $limit: Int, $where: product_bool_exp, $order_by: [product_order_by!]) {
    product(offset: $offset, limit: $limit, where: $where, order_by: $order_by) {
      ...productFields
    }
  }
`

export const GET_PRODUCTS_RELATED_COMBINE = gql`
  ${PRODUCT_FIELDS}

  query product(
    $product_where: product_bool_exp
    $product_with_related_where: product_bool_exp
    $product_without_related_where: product_bool_exp
    $offset: Int
    $limit: Int
    $order_by: [product_order_by!]
  ) {
    product_with_related: product(
      offset: $offset
      limit: $limit
      where: $product_with_related_where
      order_by: $order_by
    ) {
      ...productFields
    }
    product_without_related: product(
      offset: $offset
      limit: $limit
      where: $product_without_related_where
      order_by: $order_by
    ) {
      ...productFields
    }
  }
`

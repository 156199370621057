import { useEffect, useState } from 'react'

import { AxiosClient } from '~/clients/axios'
import { Sentry } from '~/clients/sentry'

import { IResponse, IUseProductSizeTable, IUseProductSizeTableParams } from './types'

export const useProductSizeTable = ({
  identifier,
  avatarUUID = undefined,
}: IUseProductSizeTableParams): IUseProductSizeTable => {
  const [data, setData] = useState<IResponse | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [called, setCalled] = useState<boolean>(false)

  useEffect(() => {
    const fetch = async () => {
      setLoading(true)

      try {
        const params = avatarUUID ? `identifier=${identifier}&avatar_uuid=${avatarUUID}` : `identifier=${identifier}`

        const { data } = await AxiosClient().get<IResponse>(`/outfit/sizing-table?${params}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 2000,
          timeoutErrorMessage: 'ERROR_TIMEOUT_WHILE_FETCHING_SIZE_TABLE_DATA',
        })

        setData(data)
        setLoading(false)
        setCalled(true)
      } catch (error) {
        Sentry.captureException({
          errorName: error.name,
          errorMessage: 'ERROR_WHILE_FETCHING_SIZE_TABLE_DATA',
          filePath: 'src/hooks/useProductSizeTable/index.ts',
          functionName: 'useEffect/fetch',
          payload: {
            identifier,
            avatar_uuid: avatarUUID,
          },
        })
      } finally {
        setLoading(false)
        setCalled(true)
      }
    }

    fetch()
  }, [identifier, avatarUUID])

  return {
    data,
    called,
    loading,
  }
}

import React from 'react'

import { TIconProps } from '.'

const Knitwear: React.FC<TIconProps> = ({ color, size }) => {
  return (
    <svg
      viewBox="0 0 38 32"
      data-testid="icon-knitwear"
      style={{
        width: 'auto',
        height: size,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M14.65 2.84V2.84C14.6312 2.59703 14.4924 2.37945 14.28 2.26L12.87 1.48C11.8285 0.920366 10.5953 0.840104 9.49002 1.26C8.36271 1.6902 7.46218 2.56542 7.00002 3.68L1.11002 26.2C0.80041 26.8641 1.06385 27.6545 1.71002 28L4.28002 29.37C4.58792 29.5355 4.95205 29.561 5.28001 29.44C5.60719 29.326 5.87419 29.0843 6.02002 28.77L9.00002 16C9.00002 15.7239 9.22387 15.5 9.50002 15.5C9.77616 15.5 10 15.7239 10 16C11.1343 19.8775 11.0751 24.0066 9.83002 27.85C9.7103 28.1577 9.60682 28.4715 9.52001 28.79C9.52001 30.01 13.4 31.01 18.33 31.1C18.4643 31.1027 18.5941 31.0512 18.6901 30.9571C18.786 30.8631 18.84 30.7344 18.84 30.6V26.36C18.8419 26.1919 18.7593 26.0341 18.62 25.94C18.3344 25.7849 18.1575 25.485 18.16 25.16C18.1582 24.8326 18.3344 24.5301 18.62 24.37C18.7606 24.2818 18.8442 24.1259 18.84 23.96V20C18.8419 19.8319 18.7593 19.6741 18.62 19.58C18.3344 19.4249 18.1575 19.125 18.16 18.8C18.1582 18.4726 18.3344 18.1701 18.62 18.01C18.7606 17.9218 18.8442 17.7659 18.84 17.6V13.6C18.8442 13.4341 18.7606 13.2782 18.62 13.19C18.3344 13.0299 18.1582 12.7274 18.16 12.4C18.1553 12.0744 18.3328 11.7734 18.62 11.62C18.7593 11.5259 18.8419 11.3681 18.84 11.2V7.9C18.8427 7.77004 18.7936 7.64433 18.7036 7.55056C18.6136 7.45678 18.49 7.40262 18.36 7.4C16.34 7.4 14.65 7 14.65 2.84Z"
          fill={color}
          fillRule="nonzero"
        />
        <path
          d="M36.89 26.2L31 3.68C30.5424 2.55784 29.6412 1.67478 28.51 1.24C27.4047 0.820108 26.1716 0.90037 25.13 1.46L23.72 2.24C23.5076 2.35946 23.3688 2.57703 23.35 2.82V2.82C23.35 6.96 21.66 7.38 19.71 7.42C19.58 7.42263 19.4564 7.47679 19.3664 7.57056C19.2764 7.66434 19.2273 7.79004 19.23 7.92V11.22C19.2281 11.3881 19.3107 11.5459 19.45 11.64C19.7372 11.7934 19.9147 12.0944 19.91 12.42C19.9118 12.7474 19.7356 13.0499 19.45 13.21C19.3094 13.2982 19.2258 13.4541 19.23 13.62V17.62C19.2258 17.7859 19.3094 17.9418 19.45 18.03C19.7356 18.1901 19.9118 18.4926 19.91 18.82C19.9125 19.145 19.7356 19.4449 19.45 19.6C19.3107 19.6941 19.2281 19.8519 19.23 20.02V24.02C19.2258 24.1859 19.3094 24.3418 19.45 24.43C19.7356 24.5901 19.9118 24.8926 19.91 25.22C19.9125 25.545 19.7356 25.8449 19.45 26C19.3107 26.0941 19.2281 26.2519 19.23 26.42V30.58C19.23 30.7144 19.284 30.8431 19.38 30.9371C19.4759 31.0312 19.6057 31.0827 19.74 31.08C24.67 30.99 28.55 29.99 28.55 28.77C28.4632 28.4515 28.3597 28.1377 28.24 27.83C26.9949 23.9867 26.9357 19.8575 28.07 15.98C28.07 15.7039 28.2939 15.48 28.57 15.48C28.8462 15.48 29.07 15.7039 29.07 15.98L32.07 28.75C32.2158 29.0643 32.4828 29.306 32.81 29.42C33.138 29.541 33.5021 29.5155 33.81 29.35L36.29 28C36.9362 27.6545 37.1996 26.8641 36.89 26.2Z"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Knitwear

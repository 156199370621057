/* eslint-disable no-console */
import * as smoothscroll from 'smoothscroll-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'

import App from '~/components/App'

import { dorisLog } from '~/utils/dorisLog'
import { fetchUrlParameters } from '~/utils/fetchSkuWithUrlParameters'
import { HandleGoToCartInstance } from '~/utils/handleGoToCart'
import { validationsDataOrder } from '~/utils/orderEvent/validations'
import { validateAndParseLanguage } from '~/utils/translate'

import { Sentry } from './clients/sentry'
import theme from './theme'
import checkIfHasDeprecatedStorage from './utils/checkIfHasDeprecatedStorage'
import { handleABTests } from './utils/handleABTests'
import { injectButton } from './utils/injectButton'
import { captureOrderEvent } from './utils/orderEvent'
import Tracking from './utils/tracking'
import { verify } from './utils/verify'

Sentry.init()

smoothscroll.polyfill()

const dorisIsolatedClass = 'doris-widget-isolated'

const init = config => {
  dorisLog('MIX instance started')

  checkIfHasDeprecatedStorage()

  const additionalElements = document.querySelectorAll('#doris-widget')

  additionalElements.forEach((element, index) => {
    if (index) element.remove()
  })

  const rootElement = document.getElementById('doris-widget')

  if (!config.apiKey) {
    // eslint-disable-next-line
    console.error('[DORIS][INIT] O parâmetro "apiKey" é obrigatório')

    Sentry.captureException({
      errorName: 'UNDEFINED_API_KEY',
      errorMessage: 'O parâmetro "apiKey" é obrigatório',
      filePath: 'src/index.js',
      functionName: 'init',
      payload: config,
    })
    return
  }

  localStorage.setItem('@doris:partner_apikey', config.apiKey)

  fetchUrlParameters()

  const { splashImage = '', totemExperience = false, position, language } = config

  if (position?.x) {
    theme.position.x = config.position.x
  }

  localStorage.setItem(
    '@doris:config',
    JSON.stringify({
      splash_image: splashImage,
      totem_experience: totemExperience,
      widget_position: position?.x ? position.x : 'left',
      language: validateAndParseLanguage(language || document.documentElement.lang),
    }),
  )

  Tracking.init()

  handleABTests()

  theme.colors.primary = config.theme?.colors?.primary || theme.colors.primary

  if (!rootElement) return

  rootElement.classList.add(dorisIsolatedClass)

  HandleGoToCartInstance.set({ ref: config.handleGoToCartButton })

  ReactDOM.render(
    <React.StrictMode>
      <App handleBuyButton={config.handleBuyButton} />
    </React.StrictMode>,
    rootElement,
  )
}

const start = settings => {
  dorisLog(`Starting MIX with ${settings?.skus ? `skus: ` + settings.skus : 'no skus'}`)

  const additionalElements = document.querySelectorAll('#doris-widget')

  if (!additionalElements.length) {
    dorisLog('Container not found. Widget will not be injected.')
    Sentry.captureException({
      errorName: 'WIDGET_ELEMENT_NOT_FOUND',
      errorMessage: 'The element for the widget were not found',
      filePath: 'src/index.js',
      functionName: 'start',
    })
  }

  additionalElements.forEach((element, index) => {
    if (index) {
      console.warn('[DORIS][INIT] Atenção! Não deve existir mais de uma div no DOM com o id="doris-widget"')

      if (element.childNodes.length) {
        console.warn('[DORIS][INIT] O elemento ', element, ' não deve renderizar o elemento ', element.childNodes[0])
      }
    }
  })

  let skus = []
  let defaultGender = settings?.defaultGender || null
  let defaultAgeGroup = settings?.defaultAgeGroup || null
  const webview = settings?.webview || null
  const appuser = settings?.appuser || null

  if (defaultGender && defaultGender !== 'male' && defaultGender !== 'female') {
    console.error('[DORIS][INIT] O parâmetro "defaultGender" so é válido para valores "male" ou "female"')

    Sentry.captureException({
      errorName: 'INVALID_DEFAULT_GENDER',
      errorMessage: 'O parâmetro "defaultGender" so é válido para valores "male" ou "female"',
      filePath: 'src/index.js',
      functionName: 'start',
      payload: settings,
    })
    return
  }

  if (settings?.skus) {
    skus = Array.isArray(settings?.skus) ? settings?.skus : [settings?.skus]

    skus = skus.map(sku => {
      return String(sku)
    })
  }

  const event = new CustomEvent('doris-start', {
    detail: { skus, defaultGender, defaultAgeGroup, webview, appuser },
  })

  document.dispatchEvent(event)

  dorisLog('MIX started succesfully')
}

const order = async dataOrder => {
  if (!validationsDataOrder(dataOrder)) return

  await captureOrderEvent({
    appuser: dataOrder.appuser,
    orderId: dataOrder.id,
    products: dataOrder.products,
    total: dataOrder.value,
    currency: dataOrder.currency,
  })
}

export { init, verify, start, order, injectButton }

import { FC, useEffect, useState } from 'react'

import { translate } from '~/utils/translate'

import * as Styled from './styles'
import { INTERVAL_VALUE, defaultImages, dorisGif } from './constants'
import { IStartButtonProps } from './types'

export const StartButton: FC<IStartButtonProps> = ({ onClick, images = defaultImages, defaultFlow = true }) => {
  const [alternateImage, setAlternateImage] = useState(1)

  useEffect(() => {
    const intervalKey = setInterval(() => {
      setAlternateImage(i => {
        if (i >= images.length) {
          return 1
        }
        return i + 1
      })
    }, INTERVAL_VALUE)

    return () => {
      clearInterval(intervalKey)
    }
  }, [images.length])

  return (
    <Styled.Container data-testid="start-button-container">
      <Styled.DorisGif data-testid="start-button-animation" src={dorisGif} alt="Doris Logo" width={28} height={28} />

      <Styled.Button id="start-button" onClick={onClick} data-testid="start-button" type="button">
        <Styled.BadgeNew data-testid="start-button-badge-new">
          {translate('DORIS_TRIGGER_BADGE_NEW').toUpperCase()}
        </Styled.BadgeNew>

        <Styled.ImageWrapper>
          {images.map((image, index) => (
            <Styled.Image
              key={`image_${image}`}
              visible={alternateImage === index + 1}
              src={image}
              data-testid="start-button-image"
            />
          ))}
        </Styled.ImageWrapper>

        <Styled.TextContainer data-testid="start-button-text">
          <Styled.BigText
            dangerouslySetInnerHTML={{
              __html: translate(defaultFlow ? 'DORIS_TRIGGER_TEXT' : 'DORIS_TRIGGER_TEXT_ONLY_CHART'),
            }}
          />
        </Styled.TextContainer>
      </Styled.Button>
    </Styled.Container>
  )
}

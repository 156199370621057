import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 65px);
`

export const ContentWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  padding: 16px;
`
export const ImageAndTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  height: 100%;
  overflow: hidden;
`
export const ImageWrapper = styled.div`
  width: 64px;
  min-height: 64px;
  border-radius: 8px;
  overflow: hidden;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  position: relative;
`

export const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
`

export const AdditionalInfo = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const Unit = styled.span`
  font-size: 12px;
  color: #545454;
  width: 100%;
  text-align: right;
  align-self: flex-end;
`

export const UnitBold = styled(Unit)`
  font-weight: 700;
`

export const FitFlags = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  gap: 8px;
`

export const ColoredBox = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
  font-size: 10px;
  fontweight: bold;
  line-height: 100%;
`

export const BoxFlag = styled.div`
  min-width: 12px;
  min-height: 12px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Separator = styled.span`
  font-size: 10px;
  font-weight: bold;
  color: #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
`

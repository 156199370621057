import { bool, func, string } from 'prop-types'

import { memo, useCallback } from 'react'

import useImageLoader from '~/hooks/useImageLoader'

import { SkeletonLoader } from '~/components/SkeletonLoader'

import { Image } from './styles'

const ImagePreview = ({ url, fullsize, onLoaded, onError, caching }) => {
  const { imageRef, loaded, handleOnLoad } = useImageLoader()

  const onLoad = useCallback(() => {
    handleOnLoad()
    onLoaded && onLoaded()
  }, [handleOnLoad, onLoaded])

  return (
    <>
      {!loaded && <SkeletonLoader width="100%" height="100%" />}
      <Image
        ref={imageRef}
        data-testid="image"
        src={caching ? url : `${url}?timestamp=${Date.now()}`}
        loaded={loaded}
        fullsize={fullsize}
        onLoad={onLoad}
        onError={onError}
      />
    </>
  )
}

ImagePreview.propTypes = {
  url: string.isRequired,
  fullsize: bool,
  onLoaded: func,
  onError: func,
  caching: bool,
}

ImagePreview.defaultProps = {
  fullsize: false,
  caching: true,
}

export default memo(ImagePreview)

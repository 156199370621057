import { useContextSelector } from 'use-context-selector'

import { BackdropsContext } from './context'

export const useBackdropsContext = () => {
  const isCombinedItemsVisible = useContextSelector(BackdropsContext, state => state.isCombinedItemsVisible)
  const setIsCombinedItemsVisible = useContextSelector(BackdropsContext, state => state.setIsCombinedItemsVisible)
  const handleCombinedItemsState = useContextSelector(BackdropsContext, state => state.handleCombinedItemsState)
  const isCombineVisible = useContextSelector(BackdropsContext, state => state.isCombineVisible)
  const setIsCombineVisible = useContextSelector(BackdropsContext, state => state.setIsCombineVisible)
  const isAlertSizing = useContextSelector(BackdropsContext, state => state.isAlertSizing)
  const setShowAlertSizing = useContextSelector(BackdropsContext, state => state.setShowAlertSizing)
  const isSettingsVisible = useContextSelector(BackdropsContext, state => state.isSettingsVisible)
  const setIsSettingsVisible = useContextSelector(BackdropsContext, state => state.setIsSettingsVisible)
  const isAlertMeasurements = useContextSelector(BackdropsContext, state => state.isAlertMeasurements)
  const setIsAlertMeasurements = useContextSelector(BackdropsContext, state => state.setIsAlertMeasurements)
  const handleMeasurementsAlertOpen = useContextSelector(BackdropsContext, state => state.handleMeasurementsAlertOpen)
  const isRecommendationVisible = useContextSelector(BackdropsContext, state => state.isRecommendationVisible)
  const setIsRecommendationVisible = useContextSelector(BackdropsContext, state => state.setIsRecommendationVisible)
  const isTakePictureModalVisible = useContextSelector(BackdropsContext, state => state.isTakePictureModalVisible)
  const setIsTakePictureModalVisible = useContextSelector(BackdropsContext, state => state.setIsTakePictureModalVisible)
  const onOpenNewsletter = useContextSelector(BackdropsContext, state => state.onOpenNewsletter)
  const isSendMeasurementsBackdropOpen = useContextSelector(
    BackdropsContext,
    state => state.isSendMeasurementsBackdropOpen,
  )
  const setIsSendMeasurementsBackdropOpen = useContextSelector(
    BackdropsContext,
    state => state.setIsSendMeasurementsBackdropOpen,
  )
  const isPrivacyDisclaimerVisible = useContextSelector(BackdropsContext, state => state.isPrivacyDisclaimerVisible)
  const setIsPrivacyDisclaimerVisible = useContextSelector(
    BackdropsContext,
    state => state.setIsPrivacyDisclaimerVisible,
  )

  return {
    isCombinedItemsVisible,
    setIsCombinedItemsVisible,
    handleCombinedItemsState,
    isCombineVisible,
    setIsCombineVisible,
    isAlertSizing,
    setShowAlertSizing,
    isSettingsVisible,
    setIsSettingsVisible,
    isAlertMeasurements,
    setIsAlertMeasurements,
    handleMeasurementsAlertOpen,
    isRecommendationVisible,
    setIsRecommendationVisible,
    isTakePictureModalVisible,
    setIsTakePictureModalVisible,
    onOpenNewsletter,
    isSendMeasurementsBackdropOpen,
    setIsSendMeasurementsBackdropOpen,
    isPrivacyDisclaimerVisible,
    setIsPrivacyDisclaimerVisible,
  }
}

/* eslint-disable no-console */
import { Sentry } from '~/clients/sentry'
import { dorisLog } from '~/utils/dorisLog'
import { fetchSkuFromUrl } from '~/utils/fetchSku'

import { ReactRender } from './components/ReactRender'
import { WINDOW } from './constants'
import { IInjectButtonsParams } from './types'

export const injectButton = ({
  skus,
  selector,
  validateSku,
  apiKey,
  backgroundImages,
  useNewTrigger,
  showBadge,
  description,
}: IInjectButtonsParams) => {
  const wrapper = document.querySelector(selector as string)
  const skuFromUrl = fetchSkuFromUrl()
  const providedSkus = skus || skuFromUrl

  const skusMessage = providedSkus?.length ? providedSkus : 'no SKUS'
  const selectorMessage = selector || 'no selector'
  dorisLog(`Injecting button / ${selectorMessage} / ${skusMessage} / ${validateSku ? 'true' : 'false'}`)

  if (!wrapper) {
    console.error('[DORIS][INJECTBUTTON] The "selector" parameter is required.')
    Sentry.captureException({
      errorName: 'SELECTOR_PARAMETER_NOT_FOUND',
      errorMessage: 'The "selector" parameter is required.',
      filePath: 'src/utils/injectButton/index.tsx',
      functionName: 'injectButton',
    })
    return
  }

  const breakPointInPixels = 439
  const { width } = wrapper.getBoundingClientRect()
  const hasBreak = width < breakPointInPixels

  ReactRender({
    backgroundImages,
    skus: providedSkus,
    validateSku,
    wrapper,
    apiKey,
    useNewTrigger,
    showBadge,
    hasBreak,
    description,
  })

  WINDOW.DorisWidget.rerenderStartButton = (props: Pick<IInjectButtonsParams, 'skus' | 'validateSku'>) =>
    ReactRender({
      apiKey,
      backgroundImages,
      skus: props.skus,
      validateSku: props.validateSku,
      wrapper,
      useNewTrigger,
      showBadge,
      hasBreak,
      description,
    })
}

import { useEffect, useState } from 'react'

import { useAvatar } from '~/hooks/useAvatar'
import { TUpdateAvatarInfos } from '~/hooks/useAvatar/types'

import { useAvatarContext } from '~/context/Avatar'
import { useNavigation } from '~/context/Navigation'

import { TextInput } from '~/components/Form/TextInput'
import { RoutesHeader } from '~/components/RoutesHeader'
import { Select } from '~/components/Select'
import { TermsDescription } from '~/components/Terms/components/TermsDescription'
import { TextButton } from '~/components/TextButton'
import Typography from '~/components/Typography'

import { onlyNumbers } from '~/utils/dataValidation'
import { getCollectionOptions } from '~/utils/getCollectionOptions'
import Tracking from '~/utils/tracking'
import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { IForm, IFormErrors, IFormInput, THandleInputPayload } from './types'
import { fetchFromLocalStorage, saveToLocalStorage } from './utils/formDataStorage'
import { validateBMI } from './utils/validateBMI'

export const InformData = () => {
  const { navigate, previousRoute } = useNavigation()
  const { updateAvatarInfos, resetAvatar } = useAvatar()
  const { setAvatarState, clearStates } = useAvatarContext()

  const [isValidForm, setIsValidForm] = useState(false)
  const [isValidBMI, setIsValidBMI] = useState(false)
  const [formData, setFormData] = useState<IForm>({
    gender: 0,
    height: 0,
    age: 0,
    weight: 0,
    age_group: undefined,
  })
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    gender: undefined,
    height: undefined,
    age: undefined,
    weight: undefined,
  })

  const minimumAge = formData.age_group === 'CHILDREN' ? 4 : 14
  const maximumAge = formData.age_group === 'CHILDREN' ? 14 : 99
  const minimumWeight = 10
  const minimumHeight = 80

  const shouldDisplayBMIMessage = !isValidBMI && formData.age >= minimumAge

  const partnerCollectionOptions = getCollectionOptions()

  const handleSubmit = (values: IForm) => {
    const selectedGender = partnerCollectionOptions.find(option => option.value === values.gender)?.gender
    const selectedAgeGroup = partnerCollectionOptions.find(option => option.value === values.gender)?.age_group

    const data: TUpdateAvatarInfos['data'] = {
      ...values,
      gender: selectedGender,
      age_group: selectedAgeGroup,
    }

    Tracking?.logEvent('AVATAR_DATA', {
      gender: data.gender,
      height: data.height,
      weight: data.weight,
      age: data.age,
      age_group: data.age_group,
      widget: true,
    })

    updateAvatarInfos({ data, setState: setAvatarState })
    saveToLocalStorage({ ...values, age_group: data.age_group })

    navigate('Camera')
  }

  const handleInput = ({ name, value }: IFormInput) => {
    const payload: THandleInputPayload = {
      [name]: value,
    }

    if (name === 'gender') {
      payload.age_group = partnerCollectionOptions.filter(option => option.value === value)[0].age_group
      setFormErrors(current => ({ ...current, age: undefined }))
    }

    setFormData(current => ({ ...current, ...payload }))
  }

  const getFormValidation = (form: IForm) => {
    const { age, weight, height, gender } = form

    const isAgeInvalid = age < minimumAge || age > 99
    const isWeightInvalid = weight < minimumWeight || weight > 200
    const isHeightInvalid = height < minimumHeight || height > 210
    const isGenderValid = partnerCollectionOptions.find(option => option.value === gender)

    const isBMIValid = validateBMI({ age, weight, height })

    const isInvalid = isAgeInvalid || isWeightInvalid || isHeightInvalid || !isBMIValid || !isGenderValid

    if (!isBMIValid && gender !== 0 && age >= minimumAge) {
      setIsValidBMI(false)
    } else {
      setIsValidBMI(true)
    }

    return !isInvalid
  }

  const onUpdateAge = (value: string) => {
    const parsedValue = onlyNumbers(value)
    setFormData({ ...formData, age: parsedValue })

    if (parsedValue < minimumAge || parsedValue > maximumAge) {
      const ageError: TTranslateKey =
        formData.age_group === 'CHILDREN' ? 'INFORM_DATA_AGE_SELECT_ALTERNATIVE_ERROR' : 'INFORM_DATA_AGE_SELECT_ERROR'

      setFormErrors({ ...formErrors, age: translate(ageError) })
      return
    }

    setFormErrors({ ...formErrors, age: undefined })
  }

  const onFocusAge = () => {
    setFormErrors({ ...formErrors, age: undefined })
  }

  const onUpdateHeight = (value: string) => {
    const parsedValue = onlyNumbers(value)

    setFormData({ ...formData, height: parsedValue })

    if (parsedValue < minimumHeight || parsedValue > 210) {
      const heightError: TTranslateKey = 'INFORM_DATA_HEIGHT_SELECT_ERROR'
      setFormErrors({ ...formErrors, height: translate(heightError) })
      return
    }

    setFormErrors({ ...formErrors, height: undefined })
  }

  const onFocusHeight = () => {
    setFormErrors({ ...formErrors, height: undefined })
  }

  const onUpdateWeight = (value: string) => {
    const parsedValue = onlyNumbers(value)
    setFormData({ ...formData, weight: parsedValue })

    if (parsedValue < minimumWeight || parsedValue > 200) {
      const weightError: TTranslateKey = 'INFORM_DATA_WEIGHT_SELECT_ERROR'

      setFormErrors({ ...formErrors, weight: translate(weightError) })
      return
    }

    setFormErrors({ ...formErrors, weight: undefined })
  }

  const onFocusWeight = () => {
    setFormErrors({ ...formErrors, weight: undefined })
  }

  const fillUserDataFromLocalStorage = () => {
    const savedData = fetchFromLocalStorage()

    if (savedData) {
      setFormData(savedData)
    }
  }

  useEffect(() => {
    const isFormValid = getFormValidation(formData)

    setIsValidForm(isFormValid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  useEffect(() => {
    resetAvatar({ data: ['stateAvatar', 'statePositions'], setState: clearStates })
  }, [clearStates, resetAvatar])

  useEffect(() => {
    Tracking.logEvent('AVATAR_ADD', { widget: true, origin: previousRoute })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    localStorage.removeItem('@doris:child-form-filled')

    fillUserDataFromLocalStorage()
  }, [])

  return (
    <Styled.Container data-testid="photo-inform-data-screen">
      <RoutesHeader title={translate('INFORM_DATA_HEADER')} prevButtonUrl="Models" closeButtonUrl="Models" />
      <Styled.Content>
        <Typography align="center">{translate('INFORM_DATA_DESCRIPTION')}</Typography>
        <Select
          name="gender"
          initialOption={translate('INFORM_DATA_GENDER_SELECT_PLACEHOLDER')}
          options={partnerCollectionOptions.map(item => ({
            ...item,
            label:
              translate(`MODEL_GENDER_SELECT_${item.age_group}`) +
              ' ' +
              translate(`MODEL_GENDER_SELECT_${item.gender}`),
          }))}
          value={formData.gender}
          onChange={value => handleInput({ name: 'gender', value })}
          disableInitialOption
        />
        <TextInput
          name="height"
          type="number"
          max="210"
          min="80"
          placeholder={translate('INFORM_DATA_HEIGHT_SELECT_PLACEHOLDER')}
          handleOnChange={onUpdateHeight}
          handleOnFocus={onFocusHeight}
          handleOnBlur={onUpdateHeight}
          error={formErrors.height}
          defaultValue={formData.height || ''}
        />
        <TextInput
          name="weight"
          type="number"
          max="200"
          min="10"
          placeholder={translate('INFORM_DATA_WEIGHT_SELECT_PLACEHOLDER')}
          handleOnChange={onUpdateWeight}
          handleOnFocus={onFocusWeight}
          handleOnBlur={onUpdateWeight}
          error={formErrors.weight}
          defaultValue={formData.weight || ''}
        />
        <TextInput
          name="age"
          type="number"
          max={String(maximumAge)}
          min={String(minimumAge)}
          placeholder={translate('INFORM_DATA_AGE_SELECT_PLACEHOLDER')}
          handleOnChange={onUpdateAge}
          handleOnFocus={onFocusAge}
          handleOnBlur={onUpdateAge}
          error={formErrors.age}
          defaultValue={formData.age || ''}
        />
        {shouldDisplayBMIMessage && (
          <Styled.Message data-testid="bmi-message">{translate('INFORM_DATA_BETA_VERSION_MESSAGE')}</Styled.Message>
        )}
      </Styled.Content>
      <Styled.Footer>
        <TextButton
          testID="send-inform-data-button"
          textTransform="uppercase"
          disabled={!isValidForm || !isValidBMI}
          onClick={() => handleSubmit(formData)}
        >
          {translate('INFORM_DATA_CONTINUE_BUTTON')}
        </TextButton>
        <TermsDescription />
      </Styled.Footer>
    </Styled.Container>
  )
}

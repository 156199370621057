import { FC } from 'react'

import { translate } from '~/utils/translate'
import { TTranslateKey } from '~/utils/translate/types'

import * as Styled from './styles'
import { getTagsMap } from './constants'
import { INewSizingTagProps } from './types'

export const NewSizingTag: FC<INewSizingTagProps> = ({ bodyPart, fit, gender = 'MALE', loading = false }) => {
  const bodyPartTranslationKey = getTagsMap().labels[bodyPart][gender] as TTranslateKey
  const fitTranslationKey = getTagsMap().messages[fit] as TTranslateKey

  return (
    <Styled.Container
      fit={fit}
      aria-busy={loading ? 'true' : 'false'}
      data-testid={`sizing-tag-${bodyPart}-${fit}`}
      isLoading={loading}
    >
      <Styled.BodyPart>{translate(bodyPartTranslationKey)}</Styled.BodyPart>
      <Styled.Fit>{translate(fitTranslationKey)}</Styled.Fit>
    </Styled.Container>
  )
}

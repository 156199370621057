import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow-y: auto;
`

export const Thumbnail = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

export const TableHeader = styled.th`
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  padding: 8px;
  text-align: center;
  color: #222222;

  &:first-child {
    text-align: left;
  }
`

export const TableRow = styled.tr<{ color: string }>`
  height: 50px;
  color: ${({ color }) => color} !important;
  border-left: 2px solid ${({ color }) => (color ? color : 'transparent')};
  background-color: ${({ color }) => (color ? `${color}50` : 'transparent')};
`

export const TableCell = styled.td`
  padding: 8px;
  text-align: center;
  font-size: 12px;
  line-height: 120%;
  color: #222222;
`
export const TableCellBold = styled(TableCell)<{ color: string }>`
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  text-align: left;
  color: ${({ color }) => color} !important;
`

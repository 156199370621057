import { FC, useCallback, useMemo } from 'react'

import FlatList from '~/components/FlatList'
import ImageButton from '~/components/ImageItem'
import MoreProductsButton from '~/components/MoreProductsButton'
import { ProductsSkeletonLoader } from '~/components/ProductsBackdrop/styles'
import { IProductsListProps } from '~/components/ProductsList/types'
import { SkeletonLoader } from '~/components/SkeletonLoader'

import theme from '~/theme'

import { IProduct } from '~/entities'
import { MobileUtils } from '~/utils/mobileUtils'

import * as Styled from './styles'

export const ProductsList: FC<IProductsListProps> = ({
  data,
  selectedItem,
  showTag,
  currentPage = 0,
  isLastPage = false,
  isLoading = false,
  disabled,
  onSelected,
  onNextPage,
}) => {
  const isMobile = useMemo(() => MobileUtils.isMobile(), [])

  const renderItem = useCallback(
    (item: IProduct) => {
      const imageUrl = item.thumbnail
      const shouldDisplayTag = showTag && item.id === selectedItem?.id
      const isActive = item.id === selectedItem?.id
      return (
        <ImageButton
          url={`${imageUrl}?w=300`}
          active={isActive}
          tag={shouldDisplayTag}
          onClick={() => onSelected(item)}
          disabled={disabled}
          id={item.id}
        />
      )
    },
    [disabled, onSelected, selectedItem?.id, showTag],
  )

  const renderPagination = useCallback(
    (page: number) => <MoreProductsButton onClick={() => (!isLoading ? onNextPage(page + 1) : undefined)} />,
    [isLoading, onNextPage],
  )

  const renderSkeleton = useCallback(
    () => (
      <ProductsSkeletonLoader data-testid="products-skeleton-loader">
        {Array.apply(0, Array(4)).map((_item, index) => (
          <SkeletonLoader
            key={`skeleton-${index}`}
            width="106px"
            height="100%"
            borderRadius="15px"
            styles={{ margin: index === 0 ? '0 3.5px 0 0' : '0 3.5px', flexShrink: 0 }}
          />
        ))}
      </ProductsSkeletonLoader>
    ),
    [],
  )

  const renderList = useCallback(() => {
    return (
      <Styled.Container data-testid="product-list">
        {data?.length ? (
          <FlatList
            testID="product-flat-list"
            data={data}
            renderItem={renderItem}
            isPagination
            currentPage={currentPage}
            isLastPage={isLastPage}
            renderPagination={renderPagination}
            styles={{
              container: {
                ...(isMobile && { paddingLeft: '15px' }),
                margin: '0 -20px',
              },
              item: {
                margin: '0 2px',
              },
              pagination: {
                margin: '0 2px',
              },
            }}
            disabled={disabled}
          />
        ) : (
          <Styled.Label color={theme.colors.silverChalice}>Nenhum item encontrado</Styled.Label>
        )}
      </Styled.Container>
    )
  }, [currentPage, data, disabled, isLastPage, isMobile, renderItem, renderPagination])

  return data ? renderList() : renderSkeleton()
}

import styled from 'styled-components'

import { IImageProps } from './types'

export const Button = styled.button`
  padding: 8px 24px 8px 8px;
  border-radius: 24px;
  cursor: pointer;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 292px;
  align-items: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colors.black};
  border: none;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  margin-bottom: 0;
  position: relative;
  @media ${props => props.theme.media.responsive.mobile} {
    width: 100%;
  }
`

export const ImageWrapper = styled.span`
  position: relative;
  min-width: 54px;
  width: 54px;
  height: 54px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.palette.whiteLilac};
`

export const Image = styled.img<IImageProps>`
  display: block;
  height: 100%;
  width: auto;
  transition: opacity ease 0.25s;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: ${props => (props.visible ? '1' : '0')};
`

export const TextContainer = styled.span`
  display: flex;
  color: ${({ theme }) => theme.colors.white};
  padding-top: 8px;
`

export const BigText = styled.span`
  display: block;
  font-size: 14px;
  line-height: 16.8px;
  text-align: right;
`

export const BadgeNew = styled.span`
  position: absolute;
  padding: 7px 13px;
  background-color: ${({ theme }) => theme.colors.status.error};
  border-radius: 13px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 10px;
  font-weight: 500;
  line-height: 11px;
  top: -12px;
  right: 12px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-weight: ${({ theme }) => theme.typography.fontWeight.semiBold};
`
export const Container = styled.div`
  position: relative;
  margin: 12px 0;
  padding: 12px 0 12px 12px;
`

export const DorisGif = styled.img`
  position: absolute;
  top: calc(50% - 19px);
  left: 0;
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: 16px;
  overflow: hidden;
  z-index: 2;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`

import { Balance } from '~/libs/icons/Balance'
import { CameraAdd } from '~/libs/icons/CameraAdd'

import ArrowDown from './ArrowDown'
import ArrowLeft from './ArrowLeft'
import ArrowRight from './ArrowRight'
import Audio from './Audio'
import BeachCover from './BeachCover'
import Bermuda from './Bermuda'
import Blazer from './Blazer'
import Blouse from './Blouse'
import Body from './Body'
import { Bolt } from './Bolt'
import Bra from './Bra'
import Broom from './Broom'
import { Camera } from './Camera'
import Checked from './Checked'
import Close from './Close'
import Coat from './Coat'
import Corner from './Corner'
import Cross from './Cross'
import DO from './DO'
import { DiscountStamp } from './DiscountStamp'
import Dots from './Dots'
import Download from './Download'
import Dress from './Dress'
import Email from './Email'
import External from './External'
import Hanger from './Hanger'
import Heart from './Heart'
import { Height } from './Height'
import HistoryArrow from './HistoryArrow'
import { Image } from './Image'
import Jacket from './Jacket'
import Jumpsuit from './Jumpsuit'
import Knitwear from './Knitwear'
import Legging from './Legging'
import { Locked } from './Locked'
import Nightgown from './Nightgown'
import { Padlock } from './Padlock'
import Panties from './Panties'
import Pants from './Pants'
import Plus from './Plus'
import PoloShirt from './PoloShirt'
import Robes from './Robes'
import Shape from './Shape'
import Shirt from './Shirt'
import Shop from './Shop'
import Shortall from './Shortall'
import Shorts from './Shorts'
import { Sizing } from './Sizing'
import Skirt from './Skirt'
import Spinner from './Spinner/index'
import SpinnerLoading from './SpinnerLoading'
import { Success } from './Success'
import { SuccessCheckMark } from './SuccessCheckMark'
import Sweatshirt from './Sweatshirt'
import Swimsuit from './Swimsuit'
import TShirt from './TShirt'
import TailoringBottom from './TailoringBottom'
import TailoringTop from './TailoringTop'
import TankTop from './TankTop'
import Tape from './Tape'
import Thawb from './Thawb'
import { Timer } from './Timer'
import { Trash } from './Trash'
import { TurnCamera } from './TurnCamera'
import Vest from './Vest'
import Warning from './Warning'
import Whatsapp from './Whatsapp'

export type TIconProps = {
  color: string
  size?: string | number
  rotate?: number
}

const categoryIconsMap = {
  'coats-and-jackets': Jacket,
  bermuda: Bermuda,
  blouse: Blouse,
  body: Body,
  coat: Coat,
  dress: Dress,
  nightgown: Nightgown,
  jacket: Jacket,
  jumpsuit: Jumpsuit,
  pants: Pants,
  poloshirt: PoloShirt,
  shirt: Shirt,
  shortall: Shortall,
  shorts: Shorts,
  skirt: Skirt,
  knitwear: Knitwear,
  sweater: Knitwear,
  sweatshirt: Sweatshirt,
  tshirt: TShirt,
  bra: Bra,
  panties: Panties,
  swimsuit: Swimsuit,
  'beach-cover': BeachCover,
  blazer: Blazer,
  vest: Vest,
  tailoring: TailoringTop,
  'tailoring-bottom': TailoringBottom,
  'tailoring-top': TailoringTop,
  thawbs: Thawb,
  'tank-top': TankTop,
  robe: Robes,
  legging: Legging,
}

const iconMap = {
  ...categoryIconsMap,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  audio: Audio,
  broom: Broom,
  camera: Camera,
  checked: Checked,
  close: Close,
  corner: Corner,
  cross: Cross,
  do: DO,
  bolt: Bolt,
  dots: Dots,
  download: Download,
  email: Email,
  external: External,
  hanger: Hanger,
  historyarrow: HistoryArrow,
  image: Image,
  locked: Locked,
  plus: Plus,
  shop: Shop,
  sizing: Sizing,
  spinner: Spinner,
  'spinner-loading': SpinnerLoading,
  tape: Tape,
  timer: Timer,
  trash: Trash,
  turnCamera: TurnCamera,
  warning: Warning,
  whatsapp: Whatsapp,
  success: Success,
  balance: Balance,
  height: Height,
  cameraAdd: CameraAdd,
  heart: Heart,
  shape: Shape,
  successCheckMark: SuccessCheckMark,
  padlock: Padlock,
  'discount-stamp': DiscountStamp,
}

export { iconMap, categoryIconsMap }

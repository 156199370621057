export const SEND_VERIFY_EVENT_PARTNER_API_KEYS: string[] = [
  /*
   * Doris Alternative Partner for Staging, Develop and Production
   */
  '31f98ea0a9eb69f2953dfa21c77608d9', // Doris Alternative Partner - 44
  /*
   * Develop
   */
  /*
   * Staging
   */
  /*
   * Production
   */
  '58024ee2-be58-4cc6-a845-67df031ea960', // Aramis - 116
  'b1aaf4e4-0aa3-4ddc-bc1b-7a2559be0f2a', // Decathlon - 104
  'f2c721639fbaea1a27e5298e67c67ad9', // Highstil - 40
  'e4daf8e3eaeafeca4fdae4694be53b14', // Reserva - 3
]

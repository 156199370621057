import { GET_MODEL } from '~/hooks-queries/avatar'
import { LOCAL_STORAGE_AVATARS_KEY } from '~/hooks/useAvatar/constants'
import { LOCAL_STORAGE_MODELS_KEY } from '~/hooks/useCurrentModel/constants'
import { UserStorageInstance } from '~/hooks/useUser/storage'

import { ApolloClient } from '~/clients/apollo'
import { IAvatar } from '~/entities'
import { getPartnerAPIKey } from '~/utils/getPartnerAPIKey'

import { IModelToTryon } from './types'

export const fetchAvatar = async (avatar_uuid: string): Promise<Array<IModelToTryon>> => {
  const response = await ApolloClient.query({
    query: GET_MODEL,
    variables: {
      where: { avatar_uuid: { _eq: avatar_uuid } },
    },
  })

  return response?.data.model_to_tryon || []
}

export const fetchUrlParameters = async () => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)

  const dwoa = parseInt(params.get('dwoa') as string)
  const dwuuid = params.get('dwuuid')
  const dwgender = params.get('dwgender')
  const products = params.get('dwskus')
  const dwuser = params.get('dwuser')
  const dwview = params.get('dwview')
  const dwappuser = params.get('dwappuser')
  const dwskus = products?.split(',')

  if (dwuser) {
    UserStorageInstance.set({
      uuid: dwuser,
      interactions: 0,
    })
    localStorage.setItem('@doris:terms', JSON.stringify(true))
    localStorage.setItem('@doris:walkthrough', JSON.stringify(true))
  }

  if (!dwoa) return

  if (dwuuid) {
    const response = await fetchAvatar(dwuuid)

    if (!response.length) {
      // eslint-disable-next-line no-console
      console.error('[DORIS][FETCH_URL_PARAMETERS] Erro ao coletar dados do avatar')
      return
    }

    const model = {
      id: response[0].avatar_uuid,
      skin: 1,
      stage_image:
        response[0].type === 'PRE_MODEL'
          ? response[0].pre_models[0].stage_image
          : response[0].self_models[0].stage_image,
      gender: response[0].gender,
      age_group: response[0].age_group,
      type: response[0].type,
    }

    const storageModels = localStorage.getItem(LOCAL_STORAGE_MODELS_KEY) as string
    const models = JSON.parse(storageModels)
    const modelsStorage = models || { current: null }
    const currentAPIKey = getPartnerAPIKey()

    modelsStorage.current = {
      ...modelsStorage?.current,
      [currentAPIKey]: model,
    }

    localStorage.setItem(LOCAL_STORAGE_MODELS_KEY, JSON.stringify(modelsStorage))

    if (model.type === 'SELF_MODEL') {
      const storageAvatars = localStorage.getItem(LOCAL_STORAGE_AVATARS_KEY)
      const list: Array<IAvatar> = storageAvatars ? JSON.parse(storageAvatars) : []

      if (!list.find(item => item.avatar_uuid === model.id)) {
        const avatar: IAvatar = {
          id: response[0].id,
          avatar_uuid: response[0].avatar_uuid,
          image_url: model.stage_image,
          age: response[0].age,
          gender: model.gender,
          age_group: model.age_group,
          height: response[0].height,
          weight: response[0].weight,
          apiKey: currentAPIKey,
        }

        localStorage.setItem(LOCAL_STORAGE_AVATARS_KEY, JSON.stringify([avatar, ...list]))
      }
    }
  }

  setTimeout(() => {
    const event = new CustomEvent('doris-start', {
      detail: { skus: dwskus, defaultGender: dwgender, webview: !!dwview, appuser: dwappuser },
    })
    document.dispatchEvent(event)
  }, 1000)
}

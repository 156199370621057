import { FC } from 'react'

import { usePartner } from '~/hooks/usePartner'

import { AnimationFade } from '~/components/Animations'
import { Tag } from '~/components/Tag'
import { TagDisplayTrigger } from '~/components/TagDisplayTrigger'

import { toCurrency } from '~/utils/toCurrency'
import Tracking from '~/utils/tracking'

import * as Styled from './styles'
import { ITag, ITagsProps } from './types'
import { useTags } from './useTags'

export const PriceTags: FC<ITagsProps> = ({ onHangerClick }) => {
  const { items, shouldDisplayTags, setShouldDisplayTags } = useTags()
  const { getPartnerFlags } = usePartner()

  const hidePriceByFlag = getPartnerFlags().some(flag => flag.path === 'hide_price')

  const handleTracking = () => {
    Tracking.logEvent('STAGE', { action: shouldDisplayTags ? 'close' : 'open', widget: true })
  }

  const handleTag = () => {
    setShouldDisplayTags(!shouldDisplayTags)
    onHangerClick()
  }

  const renderTag = (tag: ITag): JSX.Element | null => {
    if (tag.price && !hidePriceByFlag) {
      return (
        <AnimationFade key={`${tag.category.name}`} direction="fadeInOut" startAnimation={shouldDisplayTags}>
          <Tag icon={{ name: tag.category.name }} onClick={handleTag}>
            {toCurrency({ value: tag.price })}
          </Tag>
        </AnimationFade>
      )
    }

    return null
  }

  return (
    <>
      <TagDisplayTrigger
        active={shouldDisplayTags}
        onClick={() => {
          handleTracking()
          setShouldDisplayTags(!shouldDisplayTags)
        }}
      />
      <Styled.ContainerTags data-visible={shouldDisplayTags} data-testid="container-tags">
        {items.map(tag => renderTag(tag))}
      </Styled.ContainerTags>
    </>
  )
}

import { FC } from 'react'

import { useProductSizeTable } from '~/hooks/useProductSizeTable'

import { useNavigation } from '~/context/Navigation'

import { AppButtonClose } from '~/components/AppButtonClose'
import ImagePreview from '~/components/ImagePreview'
import { LoadingDots } from '~/components/LoadingDots'
import { RoutesHeader } from '~/components/RoutesHeader'
import { SizingTable } from '~/components/SizingTable'
import { sizingMessagesColorsMap } from '~/components/SizingTable/constants'
import { ISizingData } from '~/components/SizingTable/types'

import theme from '~/theme'

import { translate } from '~/utils/translate'

import * as Styled from './styles'

export const SizingSuggestionScreen: FC = () => {
  const { params } = useNavigation()
  const { data, loading } = useProductSizeTable({
    identifier: (params as { identifier: string })?.identifier,
  })

  return (
    <Styled.Container data-testid="sizing-table-screen-container">
      <RoutesHeader title={translate('SIZING_TABLE_SCREEN_TITLE')} />
      <AppButtonClose buttonIconColor={theme.colors.primary} buttonBackgroundColor={'transparent'} boxShadow={false} />

      <Styled.ContentWrapper>
        {loading && (
          <LoadingDots
            isVisible={true}
            iconPosition={{ vertical: 'CENTER' }}
            backgroundColor="white"
            color={theme.colors.primary}
            size="12px"
          />
        )}

        {data && !loading && (
          <Styled.ImageAndTableWrapper>
            <Styled.ImageWrapper>
              <ImagePreview url={data.thumbnail} fullsize={true} />
            </Styled.ImageWrapper>
            <Styled.TableWrapper>
              <SizingTable data={data as ISizingData} />
            </Styled.TableWrapper>

            <Styled.AdditionalInfo>
              <Styled.FitFlags>
                {data.size.find(item => item.status === 'FIT') && (
                  <Styled.ColoredBox>
                    <Styled.BoxFlag color={sizingMessagesColorsMap['FIT']} />
                    {translate('SIZING_TABLE_FIT_FLAG_FIT')}
                  </Styled.ColoredBox>
                )}

                {data.size.some(item => item.status === 'FIT') &&
                  data.size.some(item => item.status === 'SOME_LOOSE') && (
                    <Styled.Separator data-testid="separator">|</Styled.Separator>
                  )}

                {data.size.some(item => item.status === 'SOME_LOOSE') && (
                  <Styled.ColoredBox>
                    <Styled.BoxFlag color={sizingMessagesColorsMap['SOME_LOOSE']} />
                    {translate('SIZING_TABLE_FIT_FLAG_SOME_LOOSE')}
                  </Styled.ColoredBox>
                )}
              </Styled.FitFlags>

              <Styled.Unit data-testid="sizing-table-unit">
                {translate('SIZING_TABLE_UNIT_LABEL')} <Styled.UnitBold>{data.unit}</Styled.UnitBold>
              </Styled.Unit>
            </Styled.AdditionalInfo>
          </Styled.ImageAndTableWrapper>
        )}
      </Styled.ContentWrapper>
    </Styled.Container>
  )
}

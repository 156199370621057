import styled, { css } from 'styled-components'

import { getTagsMap } from './constants'
import { INewSizingTagTagStyled } from './types'

export const Container = styled.span<INewSizingTagTagStyled>`
  box-sizing: border-box;
  padding-bottom: 0;
  height: 38px;
  overflow: hidden;
  transition: max-width ease 0.5s, border-color ease 0.5s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: ${({ fit, isLoading }) => `4px solid ${isLoading ? 'rgba(0, 0, 0, 0.2)' : getTagsMap().colors[fit]}`};
  color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.tokens.spacingUnit + 'px'};
  border-radius: ${({ theme }) => `0px ${theme.tokens.spacingUnit}px ${theme.tokens.spacingUnit}px 0`};
  max-width: ${({ isLoading }) => (isLoading ? '36px' : '120px')};

  ${({ isLoading }) =>
    isLoading
      ? css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35));
      linear-gradient(90deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 4px);
    `
      : css`
          background: rgba(0, 0, 0, 0.35);
        `};

  & > span {
    white-space: nowrap;
    transition: opacity ease 0.5s;
    opacity: ${({ isLoading }) => (isLoading ? '0' : '1')};
  }
`

export const BodyPart = styled.span`
  font-size: 10px;
  line-height: 1;
  margin-bottom: 2px;
  font-weight: ${({ theme }) => theme.typography.fontWeight.regular};
`

export const Fit = styled.span`
  font-size: 10px;
  line-height: 1;
  font-weight: ${({ theme }) => theme.typography.fontWeight.medium};
`

import { TWhere } from '~/hooks-queries/categories/types'

import { TAgeGroup, TGender } from '~/entities'

export const categoriesFilterWhere = ({
  gender,
  age_group,
}: {
  gender: TGender
  age_group: TAgeGroup
}): { where: TWhere } => ({
  where: {
    active: { _eq: true },
    placeholder: { _eq: false },
    age_group: { _eq: age_group },
    _or: [{ gender: { _eq: gender } }, { gender: { _eq: 'UNISEX' } }],
  },
})

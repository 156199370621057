export type TSizingMessage = 'FIT' | 'SOME_LOOSE'

export type TSizingMapColors = {
  [key in TSizingMessage]: string
}

export enum ESizingColumnKeys {
  SIZE = 'size',
  CHEST = 'chest',
  WAIST = 'waist',
  HIP = 'hip',
}

export interface ISizeDataDetail {
  size: string
  chest?: string
  waist?: string
  hip?: string
}

export interface ISizeData {
  status: 'REGULAR' | 'FIT' | 'SOME_LOOSE' | 'SOME_TIGHT'
  data: ISizeDataDetail
}

export interface ISizingData {
  thumbnail: string
  unit: 'cm' | 'inch'
  size: ISizeData[]
}
